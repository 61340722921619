import { Route } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import DeepLink from '../pages/deep-linking/DeepLink';

const DeepLinkRoute = (
  <Route
    path={[
      '/join',
      '/sign-mandate',
      '/provision',
      '/invite-friends',
      '/book-appointment',
      '/documents',
      '/taxes',
    ]}
    component={() => (
      <AuthLayout>
        <DeepLink />
      </AuthLayout>
    )}
  />
);

export default DeepLinkRoute;
