import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDistance, isAfter } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { UserDataContext } from '../contexts';
import NotificationIcon from '../assets/icons/notification-icon.png';
import { useHistory } from 'react-router-dom';
import { IoArrowForward } from 'react-icons/io5';
import { teal } from '../theme';
import classnames from 'classnames';

const locales = { de, en: enUS };

function Notification({ notification }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { insurers, offers, documents, categories } = useContext(
    UserDataContext,
  );
  const { isRead, type, created, offer, avatar, document } = notification;

  const [image, setImage] = useState(null);

  const notificationStrings = {
    offerProvided: {
      title: t('Neue Offerte'),
      description: t('Antwort nötig.'),
    },
    savingPotentialDiscovered: {
      title: t('Sparpotenzial entdeckt'),
      description: t(''),
    },
    mandateSubmitted: {
      title: t('Brokermandat eingereicht'),
      description: t(
        'Wir haben bei Deinen Versicherungen das Maklermandat eingereicht.',
      ),
    },
    mandateActivated: {
      title: t('Brokermandat aktiviert'),
      description: t(
        'Dein Maklermandat ist nun bei Deinen Versicherungen hinterlegt',
      ),
    },
    claimMade: {
      title: t('Schaden gemeldet'),
      description: t('Wir haben den Schaden Deiner Versicherung gemeldet.'),
    },
    policyChangeRequested: {
      title: t('Policenänderung verschickt'),
      description: t('Wir haben Deinen Änderungswunsch erhalten.'),
    },
    policyChanged: {
      title: t('Policenänderung abgeschlossen'),
      description: t('Wir haben Deinen Änderungswunsch umgesetzt.'),
    },
    newInsuranceRequested: {
      title: t('Neue Offerte angefordert'),
      description: t('Wir haben Deine Nachricht erhalten.'),
    },
    offerAccepted: {
      title: t('Offerte akzeptiert'),
      description: t('Du hast diese Offerte akzeptiert.'),
    },
    offerDeclined: {
      title: t('Offerte abgelehnt'),
      description: t('Du hast diese Offerte abgelehnt.'),
    },
    offerExpired: {
      title: t('Offerte abgelaufen'),
      description: t('Diese Offerte ist abgelaufen.'),
    },
    collectiveSignatureNeeded: {
      title: t('Warten auf Kollektivunterschrift.'),
      description: t(
        'Wir können Dein Mandat einreichen, wenn alle benötigten Parteien unterschrieben haben.',
      ),
    },
    documentCreated: {
      title: t('Neues Dokument'),
      description: t('Zur Kenntnisnahme'),
    },
  };

  const offerData = offers && offer ? offers.find(o => o.id === offer) : null;
  const insurerID = avatar || offerData?.company;
  const documentData =
    document && documents ? documents.find(d => d.id === document) : null;

  useEffect(() => {
    async function getImage() {
      if (insurerID && insurers) {
        const insurer = insurers.find(i => i.id === insurerID);
        setImage(insurer?.logo?.src);
      }
    }
    getImage();
  }, [notification, insurers, avatar, insurerID]);

  const getDescription = () => {
    let description;
    if (offerData) {
      const { status, validUntil } = offerData;
      switch (status) {
        case 'accepted':
          return notificationStrings.offerAccepted.description;
        case 'declined':
          return notificationStrings.offerDeclined.description;
        default:
          return isAfter(new Date(), new Date(validUntil))
            ? notificationStrings.offerExpired.description
            : notificationStrings.offerProvided.description;
      }
    }
    if (type) {
      description = notificationStrings[type]?.description;
    }
    if (documentData) {
      const { mustBeSigned, signature } = documentData;
      if (signature) {
        return t('Sie haben dieses Dokument unterschrieben.');
      } else {
        switch (mustBeSigned) {
          case true:
            return t('Antwort nötig.');
          default:
            return notificationStrings.documentCreated.description;
        }
      }
    }
    const category = categories?.find(c => c.id === offerData?.categories[0]);
    if (category?.shortName) {
      description = `${t('Versicherungsart')}: ${t(
        `insuranceTypes::short::${category?.shortName}`,
      )}`;
    }
    if (!description) {
      description = '';
    }
    return description;
  };

  const isDisabled = !['offerProvided', 'documentCreated'].includes(type);
  const file = document ? document : offer;
  const fileType = document ? 'documents' : 'offers';

  return (
    <button
      disabled={isDisabled}
      onClick={() =>
        history.push(
          `/dashboard/${fileType}/${file}?notificationID=${notification.id}`,
        )
      }
      className={classnames(
        'flex flex-row w-full p-4 border-teal border-b last:border-0 items-center',
        { 'pointer-events-none': isDisabled },
        isRead ? 'transparent' : 'bg-lightteal',
      )}
    >
      <img
        alt='Notification'
        className='w-16 h-16 rounded-full object-cover'
        src={image || NotificationIcon}
      />
      <div className='p-4 flex-1'>
        <h5 className='text-left text-denim mb-2'>
          {notificationStrings[type]?.title}
        </h5>
        <p className='text-left text-xs text-denim'>{getDescription()}</p>
      </div>
      <div className='flex flex-col items-end'>
        {type === 'offerProvided' && <IoArrowForward size={20} color={teal} />}
        <p className='text-xs text-teal text-right'>
          {formatDistance(created, new Date(), {
            addSuffix: false,
            locale: locales[i18n.language] || locales.en,
          })}
        </p>
      </div>
    </button>
  );
}

export default Notification;
