import React from 'react';
import Button from './Button';

function InputButtonCombination({ inputProps, buttonProps }) {
  return (
    <div className='flex flex-col sm:flex-row space-y-4 space-x-0 sm:space-x-2 sm:space-y-0 justify-between sm:border border-teal rounded-full sm:pl-2'>
      <input
        className='bg-lightteal rounded-full font-serif text-lg border sm:border-0 border-teal lg:mr-4 text-denim focus:outline-none outline-none ring-0 focus:ring-0 focus:ring-teal focus:border-teal sm:focus:border-none placeholder-teal'
        {...inputProps}
      />
      <Button
        color='text-white dark:text-lightteal'
        backgroundColor='bg-teal dark:bg-sea'
        {...buttonProps}
      />
    </div>
  );
}

export default InputButtonCombination;
