import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import PDF from './PDF';
import PolicyActions from '../pages/policy/PolicyActions';
import OfferActions from '../pages/offer/OfferActions';
import { UserDataContext } from '../contexts';

const StyledCaveoText = props => (
  <p className='text-denim font-medium' {...props} />
);

export const makeCurrencyString = number =>
  new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(number)
    .replace('’', ' '); // Replace the apostrophe with an empty space

function PolicyOrOfferDetails({ policy, insurer, offer, children }) {
  const { t } = useTranslation();
  const { categories } = useContext(UserDataContext);
  const entity = policy || offer;
  const categoryShortName = categories.find(c => c.id === policy.categories[0])
    ?.shortName;

  return (
    <div className='flex flex-col xl:flex-row-reverse'>
      <div className='px-4 xl:w-1/2'>
        <PDF url={entity?.file?.src || entity?.file?.url} />
      </div>
      <div className='p-4 xl:w-1/2'>
        <div className='mb-8'>
          <div className='flex flex-col-reverse sm:flex-row justify-between'>
            <div className='flex-1 overflow-hidden my-auto h-full'>
              <h1 className='text-4xl break-words h-full'>
                {t(`insuranceTypes::short::${categoryShortName}`)}
              </h1>
            </div>
            <div className='w-32 sm:ml-8 h-full'>
              <img
                alt={insurer?.name}
                src={insurer?.logo?.src}
                className='h-20 object-contain object-left sm:object-center'
              />
            </div>
          </div>
          <p className='text-4xl text-teal font-medium'>{t(entity.subtype)}</p>
        </div>
        <StyledCaveoText>
          {t(policy ? 'Police' : 'Offertennummer')}:{' '}
          {policy ? policy?.policyNumber : offer?.offerNumber}
        </StyledCaveoText>
        <StyledCaveoText>
          {t('Jahresprämie')}:{' '}
          {makeCurrencyString(
            (policy ? policy?.premium : offer?.priceBrutto) / 100,
          )}
        </StyledCaveoText>
        {policy ? (
          <Fragment>
            <StyledCaveoText>
              {t('Start')}: {format(new Date(policy?.starts), 'dd.MM.yyyy')}
            </StyledCaveoText>
            <StyledCaveoText>
              {t('Ende')}: {format(new Date(policy?.ends), 'dd.MM.yyyy')}
            </StyledCaveoText>
          </Fragment>
        ) : (
          <Fragment>
            <StyledCaveoText>
              {t('Gültig bis')}:{' '}
              {format(new Date(offer?.validUntil), 'dd.MM.yyyy')}
            </StyledCaveoText>
            <StyledCaveoText className='font-bold'>
              {t('Nachricht')}: {offer?.text}
            </StyledCaveoText>
          </Fragment>
        )}
        {policy ? (
          <PolicyActions policy={policy} insurer={insurer} />
        ) : (
          <OfferActions offer={offer} />
        )}
      </div>
    </div>
  );
}

export default PolicyOrOfferDetails;
