import 'react-app-polyfill/ie9';
import 'remove-focus-outline';
import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import './i18n';

const store = configureStore();

const AppWithStore = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(<AppWithStore />, rootElement);
} else {
  render(<AppWithStore />, rootElement);
}

// render(<AppWithStore />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
