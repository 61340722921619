import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../contexts';

function Greeting(props) {
  const { t } = useTranslation();
  const { firstName, contactFirstName } = useContext(ProfileContext);
  const hours = new Date().getHours();
  let greeting = '';
  if (hours > 0) {
    greeting = t('Guten Morgen');
  }
  if (hours > 12) {
    greeting = t('Guten Tag');
  }
  if (hours > 17) {
    greeting = t('Guten Abend');
  }
  return (
    <h1 className='text-denim sm:text-5xl sm:leading-tight'>
      {t('greeting', {
        greetingByTime: greeting,
        name: firstName || contactFirstName,
      })}
    </h1>
  );
}

export default Greeting;
