import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { ProfileContext } from '../../contexts';
import { updateUser } from '../../api/user';
import { useHistory } from 'react-router-dom';
import FlatButton from '../../components/FlatButton';

function MandateSummary(props) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const history = useHistory();

  const { isCompany, language } = profile || {};

  const [isLoading, toggleLoading] = useState(false);
  const [mandateUrl, setMandateUrl] = useState('');
  const [vagUrl, setVagUrl] = useState('');

  useEffect(() => {
    const getDownloadURL = async name => {
      const languageCode = language ? language.slice(0, 2) : 'de';
      const reference = firebase
        .storage()
        .ref(`/assets/${name}-${languageCode}.pdf`);
      return reference.getDownloadURL();
    };
    const getUrls = async () => {
      const mandate = await getDownloadURL('mandate');
      const vag = await getDownloadURL('vag-45');
      setMandateUrl(mandate);
      setVagUrl(vag);
    };
    getUrls();
  }, [language]);

  const saveAndContinue = async () => {
    toggleLoading(true);
    const nextStep = isCompany ? 'collective-signature' : 'pension-plan';
    const body = {
      onboardingProgress: nextStep,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await updateUser(body);
    history.push(`/onboarding/${nextStep}`);
  };

  return (
    <div className='bg-flamingo min-h-full flex justify-center'>
      <div className='container max-w-4xl mx-auto my-auto p-8 '>
        <h1 className='text-denim text-2xl lg:w-1/2'>
          {t(
            'Was steht in einem Maklermandat und VAG? Das Wichtigste in Kürze.',
          )}
        </h1>
        <div className='flex flex-col lg:flex-row'>
          <div className='flex-1 lg:mr-8'>
            <p className='text-denim text-lg mt-8'>
              {t(
                'Mit deiner Unterschrift gibst du uns dein OK, dass wir deine Versicherungen digitalisieren und verwalten dürfen. ',
              )}
            </p>
            <p className='text-denim text-lg mt-8'>
              {t(
                'Unsere Dienstleistungen sind und bleiben für dich komplett kostenlos. Die Versicherungen entschädigen uns mit marktüblichen Courtagen, damit du nicht für den Service bezahlen musst.',
              )}
            </p>
          </div>
          <div className='flex-1'>
            <p className='text-denim text-lg mt-8'>
              {t(
                'Das Mandat ist keine Vollmacht – nur du kannst entscheiden, Verträge unterzeichnen, Rückerstattungen entgegennehmen usw.',
              )}
            </p>
            <p className='text-denim text-lg mt-8'>
              {t('Das Mandat ist jederzeit kündbar.')}
            </p>
            <p className='text-denim text-lg mt-8'>
              {t(
                'Einzig ist zu beachten, dass wir ab Unterzeichnung des Mandates deine bisherigen Betreuer ersetzen.',
              )}
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row mt-4 lg:mt-16 justify-end'>
          <FlatButton
            secondary
            className='lg:self-end my-2 bg-flamingo mr-4 flex-1'
            backgroundColor='bg-flamingo'
            label={t('Zum Maklermandat')}
            href={mandateUrl}
            target='_blank'
          />
          <FlatButton
            secondary
            className='lg:self-end my-2 mr-4 flex-1'
            backgroundColor='bg-denim'
            label={t('zum VAG 45')}
            href={vagUrl}
            target='_blank'
          />
          <Button
            primary
            className='lg:self-end my-2'
            backgroundColor='bg-denim'
            label={t('Finde ich super!')}
            isLoading={isLoading}
            onClick={saveAndContinue}
          />
        </div>
      </div>
    </div>
  );
}

export default MandateSummary;
