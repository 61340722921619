import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from '../../assets/icons/plus-white.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/hotline-white.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-next-white.svg';
import RequestForm from './RequestForm';
import LargeIconButton from '../../components/LargeIconButton';

function PolicyActions({ policy, insurer }) {
  const { t } = useTranslation();
  const [formToShow, setFormToShow] = useState(null);

  const buttons = [
    {
      label: t('Versicherung ändern'),
      icon: <ArrowRight />,
      backgroundColor:
        formToShow === 'changePolicy' ? 'bg-darkteal' : 'bg-teal',
      onClick: () =>
        setFormToShow(formToShow === 'changePolicy' ? null : 'changePolicy'),
      isActive: formToShow === 'changePolicy',
    },
    {
      label: t('Neue Offerte anfordern'),
      icon: <Plus height={45} />,
      backgroundColor: formToShow === 'requestOffer' ? 'bg-darksea' : 'bg-sea',
      onClick: () =>
        setFormToShow(formToShow === 'requestOffer' ? null : 'requestOffer'),
      isActive: formToShow === 'requestOffer',
    },
    {
      label: t('Hotline') + ` ${insurer.hotline}`,
      icon: <PhoneIcon />,
      backgroundColor: 'bg-ocean',
      href: `tel:${insurer.hotline}`,
    },
  ];

  return (
    <div id='policyActionContainer'>
      <div className='flex flex-row justify-start py-8 overflow-x-auto'>
        {buttons.map((button, index, { length }) => {
          if (index === length - 1) {
            return (
              <a href={button.href} key={button.label}>
                <LargeIconButton {...button} />
              </a>
            );
          }
          return (
            <button
              className='focus:outline-none outline-none'
              onClick={() => {
                button.onClick();
                // const container = document.getElementById(
                //   'policyActionContainer',
                // );
                // window.scrollTo(0, 10000);
              }}
              key={button.label}
            >
              <LargeIconButton {...button} />
            </button>
          );
        })}
      </div>
      {formToShow && (
        <RequestForm id='requestForm' policy={policy} type={formToShow} />
      )}
    </div>
  );
}

export default PolicyActions;
