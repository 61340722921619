import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CombinedTextFileField from '../../components/CombinedTextFileField';
import FlatButton from '../../components/FlatButton';
import DashboardContainer from '../dashboard/DashboardContainer';
import Policies from '../dashboard/Policies';

function MakeClaim(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedPolicy, setPolicy] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [whatHappened, setWhatHappened] = useState('');
  const [whatWasDamaged, setWhatWasDamaged] = useState('');
  const [whoCausedIt, setWhoCausedIt] = useState('');
  const [policeContact, setPoliceContact] = useState('');
  const [files, setFiles] = useState({
    whatHappened: null,
    whatWasDamaged: null,
    whoCausedIt: null,
    policeContact: null,
  });

  const fileArray = Object.values(files).filter(file => !!file);

  const fields = [
    {
      placeholder: t('Was ist passiert? Wann und wo?'),
      text: whatHappened,
      onTextChange: setWhatHappened,
      name: 'whatHappened',
    },
    {
      placeholder: t('Wer/was ist geschädigt oder beschädigt worden?'),
      text: whatWasDamaged,
      onTextChange: setWhatWasDamaged,
      name: 'whatWasDamaged',
    },
    {
      placeholder: t('Wer hat den Schaden verursacht (Kontaktangaben)?'),
      text: whoCausedIt,
      onTextChange: setWhoCausedIt,
      name: 'whoCausedIt',
    },
    {
      placeholder: t('Falls vorhanden Angaben zur Polizei (Kontaktangaben):'),
      text: policeContact,
      onTextChange: setPoliceContact,
      name: 'policeContact',
    },
  ];

  const sendClaim = async () => {
    try {
      setLoading(true);
      setError(null);
      const claimRef = firebase
        .firestore()
        .collection('claims')
        .doc();
      const downloadUrls = [];
      let counter = 1;
      // Upload files:
      for (const file of fileArray) {
        const path = `/userUploads/claims/${
          claimRef.id
        }/${new Date().toISOString()}_file${counter}_${file.name}`;
        const reference = firebase.storage().ref(path);
        const uploadTask = reference.put(file, {
          cacheControl: 'max-age=31536000',
        });
        const downloadUrl = await uploadTask.then(() =>
          reference.getDownloadURL(),
        );
        downloadUrls.push(downloadUrl);
        counter += 1;
      }
      // Create claim:
      await claimRef.set({
        user: firebase.auth().currentUser.uid,
        policy: selectedPolicy,
        whatHappened,
        whatWasDamaged,
        whoCausedIt,
        policeContact,
        files: downloadUrls,
        created: new Date(),
      });
      firebase.analytics().logEvent('claim_reported', { platform: 'web' });
      setLoading(false);
      history.push('/dashboard/claim-sent');
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <div className='p-8'>
      <h1 className='mb-8'>
        {t('Versicherung wählen und Formular ausfüllen')}
      </h1>
      <div className='flex flex-col xl:flex-row items-start space-y-4 space-x-0 xl:space-x-4 xl:space-y-0'>
        <DashboardContainer className='flex-1'>
          <Policies
            onPolicyClick={policyID =>
              setPolicy(val => (val === policyID ? null : policyID))
            }
            selectedPolicy={selectedPolicy}
          />
        </DashboardContainer>
        <div className='flex-1 w-full'>
          {fields.map(field => (
            <CombinedTextFileField
              {...field}
              key={field.name}
              hideSendButton
              onTextChange={e => field.onTextChange(e.target.value)}
              onFileChange={file =>
                setFiles(obj => ({ ...obj, [field.name]: file }))
              }
              file={files[field.name]}
            />
          ))}
        </div>
      </div>
      <div className='flex flex-col items-end'>
        <FlatButton
          onClick={sendClaim}
          label={t('Schadenprotokoll übermitteln')}
          isLoading={isLoading}
          disabled={
            !selectedPolicy || !whatHappened || !whatWasDamaged || !whoCausedIt
          }
        />
        {error && <p className='text-red'>{error}</p>}
      </div>
    </div>
  );
}

export default MakeClaim;
