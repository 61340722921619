import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import FlatButton from '../../components/FlatButton';
import CaveoLoader from '../../components/CaveoLoader';
import { useHistory } from 'react-router-dom';
import SignaturePad from '../../components/SignaturePad/SignaturePad';

const StatusText = ({ children }) => (
  <div className='py-2 px-4 border border-denim rounded-md my-4 inline-block'>
    <p className='text-lg font-semibold text-denim'>{children}</p>
  </div>
);

function DocumentActions({ document }) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const signaturePad = useRef(null);
  const history = useHistory();

  const { id } = document;

  const signDocument = async () => {
    if (signaturePad.current.isEmpty()) {
      return alert(t('Bitte unterschreibe im grünen Feld.'));
    }
    setLoading(true);
    const base64PNG = signaturePad.current.getTrimmedCanvas().toDataURL();
    const userID = firebase.auth().currentUser.uid;
    try {
      const reference = firebase
        .storage()
        .ref(`signatures/documents/${id}.png`);
      await reference.putString(base64PNG, 'data_url');
      // Sign mandate, save to database and continue:
      const downloadUrl = await reference.getDownloadURL();
      const signaturePath = reference.fullPath;
      if (!downloadUrl || !signaturePath) {
        throw new Error('Could not get download URL or signature path.');
      }
      // Update document in database:
      await firebase
        .firestore()
        .collection('documents')
        .doc(id)
        .update({
          signature: downloadUrl,
          modified: new Date(),
          signedAt: new Date(),
        });
      // Sign the PDF on the server:
      firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('signDocument')({
        userID,
        documentID: id,
      });
      history.push(`/dashboard/documents/${id}/signed`);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <CaveoLoader />;
  }

  if (document?.signature) {
    return (
      <StatusText>
        {t('Sie haben das Dokument unterschrieben. Dankeschön.')}
      </StatusText>
    );
  }

  return (
    <div className='mt-8'>
      <h4>{t('Dokument im Feld unten unterschreiben')}</h4>
      <SignaturePad ref={signaturePad} className='bg-lightteal border-teal' />
      <div className='flex'>
        <FlatButton
          primary
          color='text-white'
          backgroundColor='bg-arctic'
          label={t('Dokument einreichen')}
          onClick={signDocument}
          isLoading={isLoading}
        />
      </div>
      {error && <p className='text-red'>{error}</p>}
    </div>
  );
}

export default DocumentActions;
