import React from 'react';
import firebase from 'firebase/compat/app';
import { Text, Button, Box } from 'grommet';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import CaveoLoader from '../../components/CaveoLoader';
import { connect } from 'react-redux';
import { getUserInfo, setUser } from '../../store/actions/index';
import { createUser } from '../../store/actions/user';

const mapDispatchToProps = dispatch => ({
  getUserInfo: user => dispatch(getUserInfo(user)),
  setUser: user => dispatch(setUser(user)),
});

const mapStateToProps = state => ({
  signedIn: state.user.signedIn,
  user: state.user,
});

class FinishSignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // ? Maybe add redirect URL?
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt(
          this.props.t('Bitte gib deine E-Mail-Adresse zur Bestätigung ein.'),
        );
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(async result => {
          window.localStorage.removeItem('emailForSignIn');
          if (result.additionalUserInfo.isNewUser) {
            // Create user document in Firestore:
            await createUser(result.user.uid, {
              onboardingProgress: 'create-profile',
              isCompany: true,
              email,
            });
            this.props.history.push('/onboarding/create-profile');
          } else {
            this.props.history.push('/');
          }
        })
        .catch(error => {
          // Common errors could be invalid email and invalid or expired OTPs.
          this.setState({ error });
        });
    }
  }

  render() {
    return (
      <div className='container mx-auto my-auto h-full'>
        {this.state.error ? (
          <Box gap='medium'>
            <Text>{this.state.error.message}</Text>
            <p>
              Vielleicht klappt's so: Kopiere den Link (In Caveo anmelden) in
              der E-Mail und füge ihn in die Adresszeile deines Browsers ein.
            </p>
            <Button
              label={this.props.t("Versuch's nochmals")}
              primary
              onClick={() => this.props.history.push('/sign-in')}
            />
          </Box>
        ) : (
          <Box gap='medium' align='center'>
            <CaveoLoader />
          </Box>
        )}
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(FinishSignIn)),
);
