import React from 'react';

function LargeIconButton({ label, icon, color, backgroundColor }) {
  return (
    <div
      className={`p-4 items-center justify-center w-32 h-32 mr-4 flex flex-col shadow-lg hover:shadow-xl ${backgroundColor ||
        'bg-teal'} rounded-xl`}
    >
      <p
        className={`${color ||
          'text-white'} font-semibold text-center line-clamp-2`}
      >
        {label}
      </p>
      {React.cloneElement(icon, { className: 'w-12 h-12' })}
    </div>
  );
}

export default LargeIconButton;
