import firebase from 'firebase/compat/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FlatButton from '../../components/FlatButton';

function OfferSigned(props) {
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('offer_signed', { platform: 'web' });
  }, []);

  return (
    <div className='px-8'>
      <h1 className='text-denim mb-8 leading-tight'>
        {t(
          'Vielen Dank. Wir haben deine Offerte erhalten. Sobald wir die Police erhalten haben, werden wir diese in Ihre Übersicht laden.',
        )}
      </h1>
      <FlatButton secondary to='/dashboard' label={t('Zurück zum Dashboard')} />
    </div>
  );
}

export default OfferSigned;
