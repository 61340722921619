import React from 'react';
import { Box, Form, Text, Anchor } from 'grommet';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import AsyncButton from '../AsyncButton';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../Button';
import { vagDownloadLink } from '../../settings';
import InputButtonCombination from '../InputButtonCombination';
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
} from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { createUser, getUser } from '../../store/actions/user';

export default function PhoneAuth({
  setRecaptcha,
  startTimer,
  setState,
  state,
  errorStrings,
  history,
  timerLength,
  confirmationResult,
  setConfirmationResult,
  resolver,
  timer,
}) {
  const { t } = useTranslation();

  const auth = firebase.auth();
  const user = auth?.currentUser;

  const {
    mode,
    isLoading,
    showCodeInput,
    code,
    phone,
    error,
    countdown,
  } = state;

  const enrollPhoneNumber = async () => {
    setState({ isLoading: true, error: null });
    try {
      // Request verificationCode from user
      const multiFactorUser = multiFactor(user);
      const session = await multiFactorUser.getSession();
      const phoneOptions = {
        phoneNumber: phone,
        session,
      };
      // Sends a text message to the user
      const recaptchaVerifier = setRecaptcha();
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      await phoneAuthProvider
        .verifyPhoneNumber(phoneOptions, recaptchaVerifier)
        .then(verificationId =>
          setState(
            {
              showCodeInput: true,
              showPhoneInput: false,
              code: '',
              verificationId,
            },
            startTimer(),
          ),
        );
    } catch (e) {
      if (e.code === 'auth/requires-recent-login') {
        toast.error(
          t(
            'Bitte melden Sie sich erneut an, um diesen Vorgang abzuschließen.',
          ),
        );
        await auth.signOut();
        window.location.href = '/';
      }
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const submitPhoneNumber = async () => {
    setState({
      isLoading: true,
      error: null,
      code: '',
      countdown: timerLength,
    }); // Reset state if this is not the first time to request a code.
    if (timer) {
      clearInterval(timer); // Reset the timer if one is still running.
    }
    window.recaptchaVerifier = setRecaptcha(); // Re-render the recaptcha for cases when the user requests a new code
    const recaptchaVerifier = window.recaptchaVerifier;
    try {
      await firebase
        .auth()
        .signInWithPhoneNumber(phone, recaptchaVerifier)
        .then(confirmationResult => {
          // SMS sent. Prompt user to type the code from the message, then -the
          // user in with confirmationResult.confirm(code).
          setConfirmationResult(confirmationResult);
          setState({
            showCodeInput: true,
            showPhoneInput: false,
            showTimer: true,
          });
          timer = setInterval(() => {
            if (state.countdown > 0) {
              setState(state => ({ countdown: state.countdown - 1 }));
            } else {
              clearInterval(timer);
            }
          }, 1000);
        });
    } catch (e) {
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const resendCode = async () => {
    setState({ isLoading: true, error: null });
    try {
      let phoneOptions = {};
      if (resolver) {
        phoneOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
      } else {
        const multiFactorUser = multiFactor(user);
        const session = await multiFactorUser.getSession();
        phoneOptions = {
          phoneNumber: phone,
          session,
        };
      }
      const recaptchaVerifier = setRecaptcha();
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      await phoneAuthProvider
        .verifyPhoneNumber(phoneOptions, recaptchaVerifier)
        .then(verificationId => {
          setState(
            {
              showCodeInput: true,
              code: '',
              verificationId,
              countdown: timerLength,
            },
            startTimer(),
          );
        });
    } catch (e) {
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const submitCode = async () => {
    setState({ isLoading: true, error: null });
    try {
      const { code, verificationId } = state;
      if (confirmationResult) {
        // User is signing in with the phone number
        await confirmationResult.confirm(code);
      } else if (resolver) {
        // User is logging in with the second factor
        const credential = firebase.auth.PhoneAuthProvider.credential(
          verificationId,
          code,
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
          credential,
        );
        await resolver.resolveSignIn(multiFactorAssertion);
      } else {
        // User is enrolling into multifactor authentication
        const credential = firebase.auth.PhoneAuthProvider.credential(
          verificationId,
          code,
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
          credential,
        );
        const multiFactorUser = multiFactor(user);
        await multiFactorUser.enroll(multiFactorAssertion);
      }
      // Check if the firestore user exists. If there's no user doc, create it.
      const firebaseUser = await getUser(user.uid);
      if (!firebaseUser) {
        // Create user document in Firestore:
        await createUser(user.uid, {
          email: user.email,
          phoneNumber: phone,
          isCompany: false,
        });
      }
      window.location.href = '/';
    } catch (e) {
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const isEnrolling = mode === 'enroll';

  if (showCodeInput) {
    return (
      <div className='flex flex-col justify-between'>
        <h3 className='mb-4'>
          {phone
            ? t('codeSentTo', {
                phoneNumber: phone,
              })
            : t('Geben Sie den SMS-Bestätigungscode ein.')}
        </h3>
        <Box gap='medium'>
          <input
            autoComplete='off'
            className='bg-lightteal rounded-full border border-teal font-serif text-lg pl-4 my-4 lg:mr-4 text-denim focus:outline-none focus:ring focus:ring-ocean placeholder-teal'
            name='code'
            placeholder={t('Code eingeben')}
            onChange={event => setState({ code: event.target.value })}
            value={code}
            type='tel'
          />
          {error && <Text color='red'>{error}</Text>}
          <Button
            primary
            fill='horizontal'
            disabled={!code}
            label={t('Senden')}
            onClick={submitCode}
            isLoading={isLoading}
          />
          {isEnrolling && showCodeInput && (
            <Button
              primary
              fill='horizontal'
              disabled={!code}
              label={t('Rufnummer ändern')}
              onClick={() =>
                setState({
                  showCodeInput: false,
                  showPhoneInput: true,
                  code: '',
                  phone: '+41',
                })
              }
              isLoading={isLoading}
            />
          )}
          <AsyncButton
            plain={!error}
            primary={!!error}
            label={
              !error && countdown > 0
                ? t('requestNewCodeIn', {
                    countdown: countdown,
                  })
                : t('Neuen Code anfordern')
            }
            disabled={!error && countdown > 0}
            onClick={resendCode}
            isLoading={isLoading}
            id='sign-in-button'
          />
          <Text textAlign='center' size='xsmall'>
            <Trans i18nKey='onboarding//CreateAccount::termsAndConditions'>
              Mit dem Absenden der Daten akzeptieren Sie die{' '}
              <Anchor target='_blank' href='https://www.caveo.ch/terms'>
                AGB
              </Anchor>{' '}
              und{' '}
              <Anchor target='_blank' href={vagDownloadLink}>
                das VAG Art. 45 der Caveo AG
              </Anchor>
            </Trans>
          </Text>
        </Box>
      </div>
    );
  }

  return (
    <Form>
      <div className='flex flex-col justify-between'>
        {mode === 'enroll' && (
          <>
            <h1>{t('2-Faktor-Authentifizierung')}</h1>
            <p className='my-4'>
              {t(
                'Caveo verwendet jetzt eine 2-Faktor-Authentifizierung mit E-Mail und Passwort. Du wirst aufgefordert, ein Passwort und einen Bestätigungscode einzugeben.',
              )}
            </p>
          </>
        )}
        <h3
          className={`text-denim leading-tight ${
            isEnrolling ? 'mb-4' : 'mb-20'
          }`}
        >
          {isEnrolling
            ? t('Geben Sie Ihre Rufnummer ein')
            : t('Melde dich mit deiner Mobilnummer an.')}
        </h3>
        <InputButtonCombination
          inputProps={{
            autoComplete: 'on',
            type: 'tel',
            value: phone,
            onChange: e =>
              setState({
                phone: e.target.value,
              }),
          }}
          buttonProps={{
            isLoading,
            disabled: isLoading,
            label: t('Weiter'),
            id: 'sign-in-button',
            onClick: () =>
              isEnrolling ? enrollPhoneNumber() : submitPhoneNumber(),
          }}
        />
        {error && <Text color='status-error'>{error?.message || error}</Text>}
        {!user && (
          <Link
            className='mt-8'
            to={`${history.location.pathname}?type=business`}
          >
            {t('Kein Privatkunde? Dann klicke hier.')}
          </Link>
        )}
      </div>
    </Form>
  );
}
