import firebase from 'firebase/compat/app';
import { convertQuerySnapshot } from 'firebase-helpers';
import { logError } from '../utils/logging';

export function listenToOffers(handleNewData) {
  return firebase
    .firestore()
    .collection('offers')
    .where('user', '==', firebase.auth().currentUser.uid)
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData([]);
      } else {
        const data = convertQuerySnapshot(snapshot).filter(
          entry => !entry.deleted,
        );
        handleNewData(data);
      }
    }, logError);
}
