import { Anchor, Box, ResponsiveContext } from 'grommet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '../Avatar';

function ChangeableAvatar({ image, handleChange, clearImage }) {
  const { t } = useTranslation();
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction={size === 'small' ? 'column' : 'row'}
          align={size === 'small' ? 'center' : 'start'}
        >
          <Avatar withLightbox size='100px' image={image} />
          <Box
            pad='large'
            gap={size === 'small' ? 'large' : 'medium'}
            justify='center'
            direction={size === 'small' ? 'row' : 'column'}
          >
            <label htmlFor='upload'>
              <Anchor aria-hidden='true'>{t('Bild ändern')}</Anchor>
              <input
                type='file'
                accept='image/*'
                id='upload'
                style={{ display: 'none' }}
                onChange={handleChange}
              />
            </label>
            {image && <Anchor onClick={clearImage}>{t('Bild löschen')}</Anchor>}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}

export default ChangeableAvatar;
