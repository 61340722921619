import React from 'react';
import StoreBadges from '../StoreBadges';
import { useTranslation } from 'react-i18next';

export default function GetApp() {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col justify-between'>
      <h3 className='mb-4'>{t('Bitte fahre in der App weiter.')}</h3>
      <StoreBadges />
    </div>
  );
}
