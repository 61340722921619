import firebase from 'firebase/compat/app';
import { convertQuerySnapshot } from 'firebase-helpers';
import { logError } from '../utils/logging';

export function listenToDocuments(handleNewData) {
  return firebase
    .firestore()
    .collection('documents')
    .where('user', '==', firebase.auth().currentUser.uid)
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData([]);
      } else {
        const data = convertQuerySnapshot(snapshot);
        handleNewData(data);
      }
    }, logError);
}

export const markDocumentAsSeen = documentID =>
  firebase
    .firestore()
    .collection('documents')
    .doc(documentID)
    .update({ seenAt: new Date() });
