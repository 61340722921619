import React, { useContext, useEffect } from 'react';
import Notification from '../../components/Notification';
import { markNotificationsAsRead } from '../../store/actions';
import { UserDataContext } from '../../contexts';

function NotificationsList({ teaserOnly }) {
  let { notifications } = useContext(UserDataContext);

  useEffect(() => {
    const markAsRead = () => {
      // When unmounting this component, mark all new notifications as read except offers (those need to be opened first):
      const notificationIDs = notifications
        .filter(n => !n.isRead && n.type !== 'offerProvided')
        .map(n => n.id);
      if (notificationIDs.length > 0) {
        markNotificationsAsRead(notificationIDs);
      }
    };
    if (notifications) {
      return markAsRead;
    }
  }, [notifications]);

  if (!notifications) {
    return null;
  }

  if (teaserOnly) {
    notifications = notifications.slice(0, 5);
  }

  return (
    <div className='max-w-2xl'>
      {notifications
        .sort((a, b) => b.created.valueOf() - a.created.valueOf())
        .map(notification => (
          <Notification key={notification.id} notification={notification} />
        ))}
    </div>
  );
}

export default NotificationsList;
