import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import PDF from '../../components/PDF';
import Modal from '../../components/Modal';
import { UserDataContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';
import { logError } from '../../utils/logging';

function UserUpload() {
  const { t } = useTranslation();
  const history = useHistory();
  const [showModal, toggleModal] = useState(false);
  const { userUploadID } = useParams();
  const { userUploads } = useContext(UserDataContext);
  const userUpload =
    userUploads && userUploads.find(p => p.id === userUploadID);
  const isPdf = userUpload?.file?.title?.split('.').pop() === 'pdf';
  const isImg = ['jpg', 'png', 'jpeg'].includes(
    userUpload?.file?.title?.split('.').pop(),
  );
  if (!userUploads) {
    return null;
  }
  // If there's no user upload to show because the ID isn't correct or there are no uploads, redirect to user uploads list
  if (!userUpload) {
    history.push('/dashboard/user-uploads');
  }

  const deleteUserUpload = async () => {
    try {
      await firebase
        .firestore()
        .doc(`userUploads/${userUploadID}`)
        .update({ deleted: true });
      history.push('/dashboard/user-uploads');
    } catch (e) {
      logError(e);
    }
  };

  const DeleteButton = ({ onClick }) => (
    <Button
      onClick={onClick}
      label={t('Löschen')}
      className='my-4 hover:bg-red'
      backgroundColor='bg-red'
    />
  );

  return (
    <div className='flex flex-col xl:flex-row-reverse'>
      <div className='px-4 xl:w-1/2'>
        {isPdf && <PDF url={userUpload?.file?.src} />}
        {isImg && (
          <img
            className={'p-4'}
            alt={userUpload?.file?.title}
            src={userUpload?.file?.src}
          />
        )}
      </div>
      <div className='p-4 xl:w-1/2'>
        <div className='mb-8'>
          <h1 className='text-4xl break-words h-full'>{userUpload?.title}</h1>
        </div>
        <div className='flex space-x-4'>
          <DeleteButton onClick={() => toggleModal(true)} />
          <Button
            href={userUpload?.file?.src}
            target='_blank'
            label='Download'
            className={'my-4 hover:bg-teal'}
            backgroundColor='bg-teal'
          />
        </div>
      </div>
      <Modal isVisible={showModal} close={() => toggleModal(false)}>
        <div className='flex flex-col justify-between'>
          <h3 className='text-denim mb-4'>{t('Dokument wirklich löschen?')}</h3>
          <p className='text-denim text-lg'>
            {t('Das kannst du nicht rückgängig machen.')}
          </p>
          <DeleteButton onClick={deleteUserUpload} />
        </div>
      </Modal>
    </div>
  );
}

export default UserUpload;
