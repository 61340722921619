import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import auth from './reducers/auth';
import user from './reducers/user';
import ui from './reducers/ui';
import notifications from './reducers/notifications';
import policies from './reducers/policies';
import offers from './reducers/offers';

const appReducer = combineReducers({
  auth,
  user,
  ui,
  notifications,
  policies,
  offers,
});

export default function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // // Grab the state from a global variable injected into the server-generated HTML
  // const preloadedState = window.__PRELOADED_STATE__;

  // // Allow the passed state to be garbage-collected
  // delete window.__PRELOADED_STATE__;

  // Create Redux store with initial state
  return createStore(
    appReducer,
    // preloadedState,
    composeEnhancers(applyMiddleware(thunk)),
  );
}
