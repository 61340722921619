import React from 'react';
import { useTranslation } from 'react-i18next';
import FlatButton from '../../components/FlatButton';

function FileUploaded() {
  const { t } = useTranslation();
  return (
    <div className='px-8'>
      <h1 className='text-denim mb-8 leading-tight'>
        {t('Dokument erfolgreich hochgeladen.')}
      </h1>
      <FlatButton secondary to='/dashboard' label={t('Zurück zum Dashboard')} />
    </div>
  );
}

export default FileUploaded;
