import React from 'react';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { useTranslation } from 'react-i18next';
import FlatButton from '../../components/FlatButton';
import { updateUser } from '../../api/user';
import { useHistory } from 'react-router-dom';

function OnboardingFinished() {
  const { t } = useTranslation();
  const history = useHistory();

  const goToDashboard = async () => {
    // Mark onboarding process as finished:
    await updateUser({
      didFinishOnboarding: true,
      status: 'active',
      type: 'user',
    });
    history.push('/dashboard');
  };

  return (
    <OnboardingLayout backgroundColor='bg-teal'>
      <div className='lg:w-1/2'>
        <h1 className='text-denim mb-8 leading-tight'>
          {t('Du hast es geschafft. Willkommen bei Caveo!')}
        </h1>
        <FlatButton
          secondary
          label={t('Zu meinem Profil')}
          onClick={goToDashboard}
          type='submit'
          className='bg-denim text-white lg:float-right'
        />
      </div>
    </OnboardingLayout>
  );
}

export default OnboardingFinished;
