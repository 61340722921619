import React from 'react';
import firebase from 'firebase/compat/app';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { shortLanguage } from '../../i18n';
import { RecaptchaVerifier } from 'firebase/auth';
import StoreBadges from '../StoreBadges';
import Button from '../Button';
import { connect } from 'react-redux';
import { getUserInfo, setUser } from '../../store/actions/index';
import PhoneAuth from './PhoneAuth';
import EmailAndPasswordAuth from './EmailAndPasswordAuth';

const mapDispatchToProps = dispatch => ({
  getUserInfo: user => dispatch(getUserInfo(user)),
  setUser: user => dispatch(setUser(user)),
});

const mapStateToProps = state => ({
  signedIn: state.user.signedIn,
  user: state.user,
});

class MultiFactorAuth extends React.Component {
  constructor(props) {
    super(props);
    this.timerLength = 45;
    this.auth = firebase.auth();
    this.user = this.auth?.currentUser;
    this.state = {
      email: '',
      isLoading: false,
      emailWasSent: false,
      error: null,
      phone: '+41',
      code: '',
      showPasswordInput: false,
      showPhoneInput: false,
      showCodeInput: false,
      showTimer: false,
      countdown: this.timerLength,
      showGetApp: true,
      verificationId: null,
    };
    const { t } = this.props;
    this.errorStrings = {
      'auth/captcha-check-failed': t('components//PhoneAuth::captchaCheck'),
      'auth/invalid-phone-number': t('components//PhoneAuth::invalidNumber'),
      'auth/missing-phone-number': t('components//PhoneAuth::noNumber'),
      'auth/quota-exceeded': t('components//PhoneAuth::quotaExceeded'),
      'auth/user-disabled': t('components//PhoneAuth::userDisabled'),
      'auth/invalid-verification-code': t('components//PhoneAuth::invalidCode'),
      'auth/invalid-email': t('components//PhoneAuth::invalidEmail'),
      'auth/user-not-found': t('components//PhoneAuth::userNotFound'),
      'auth/wrong-password': t('components//PhoneAuth::wrongPassword'),
    };
  }

  componentDidMount() {
    this.auth.languageCode = shortLanguage;
    const { pathname } = window.location;
    if (pathname.startsWith('/enroll')) {
      if (!this.user) {
        window.location.href = '/';
      }
      this.setState({
        showGetApp: false,
        mode: 'enroll',
        ...(pathname.endsWith('/phone')
          ? { showPhoneInput: true, phone: '+41' }
          : { showPasswordInput: true, password: '' }),
      });
    }
    if (!this.state.showGetApp) {
      window.recaptchaVerifier = this.setRecaptcha();
    }
  }

  setRecaptcha = () =>
    new RecaptchaVerifier(this.auth, 'sign-in-button', {
      size: 'invisible',
      badge: 'bottomleft',
    });

  startTimer = () => {
    this.timer = setInterval(() => {
      if (this.state.countdown > 0) {
        this.setState(state => ({
          countdown: state.countdown - 1,
        }));
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  };

  render() {
    const { showCodeInput, showGetApp, showPhoneInput } = this.state;
    const { t, history } = this.props;

    if (showGetApp) {
      return (
        <div className='flex flex-col min-h-screen sm:min-h-0'>
          <h3 className='text-denim leading-tight'>
            {t(
              'Hast du gewusst? Caveo gibt es auch als App. Als Privatkunde empfehlen wir dir, unser App zu nutzen.',
            )}
          </h3>
          <StoreBadges />
          <Button
            onClick={() => this.setState({ showGetApp: false })}
            className='mt-8 sm:max-w-sm'
            label={t(
              'Ich bin bereits Kunde und möchte mich hier im Webbrowser anmelden.',
            )}
          />
        </div>
      );
    }

    const commonProps = {
      setRecaptcha: this.setRecaptcha,
      startTimer: this.startTimer,
      setState: (newStates, callback) =>
        this.setState({ ...newStates }, () => (callback ? callback() : null)),
      state: this.state,
      errorStrings: this.errorStrings,
      history,
      timerLength: this.timerLength,
      confirmationResult: this.confirmationResult,
      setConfirmationResult: newConfirmationResult =>
        (this.confirmationResult = newConfirmationResult),
      resolver: this.resolver,
      setResolver: newResolver => (this.resolver = newResolver),
      timer: this.timer,
    };

    if (showCodeInput || showPhoneInput) {
      return <PhoneAuth {...commonProps} />;
    }

    return <EmailAndPasswordAuth {...commonProps} />;
  }
}

const AuthWithTranslation = withTranslation()(MultiFactorAuth);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AuthWithTranslation));
