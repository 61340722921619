import React, { useContext, useMemo } from 'react';
import firebase from 'firebase/compat/app';
import { Box, ResponsiveContext } from 'grommet';
import Header from '../components/Header';
import { arctic, denim } from '../theme';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import {
  AiOutlineFire,
  AiOutlineHome,
  AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import {
  IoBookOutline,
  IoCashOutline,
  IoChatbubblesOutline,
} from 'react-icons/io5';
import { ProfileContext } from '../contexts';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import LanguageSwitcher from '../components/LanguageSwitcher';
import CryptoJS from 'crypto-js';

function DashboardLayout(props) {
  const { t } = useTranslation();
  const { image, fullName, isCompany } = useContext(ProfileContext);

  const riskcockpitUrl = useMemo(() => {
    // Pass encrypted unlock code to the url to be used in the riskcockpit app
    const encryptedUnlockCode = CryptoJS.AES.encrypt(
      'caveo2022',
      'caveo-riskcockpit',
    ).toString();
    const encodedCode = encodeURIComponent(encryptedUnlockCode);
    return `https://www.risikocockpit.ch/caveo/wizard?voucher=${encodedCode}`;
  }, []);

  const upperLinks = [
    {
      label: t('Home'),
      to: '/dashboard',
      Icon: AiOutlineHome,
    },
    {
      label: t('Schaden melden'),
      to: '/dashboard/make-claim',
      Icon: AiOutlineFire,
    },
    {
      label: t('Blog'),
      href: 'https://www.caveo.ch/blog',
      Icon: IoBookOutline,
    },
    {
      label: t('Chatte mit uns'),
      onClick: () => {
        window.Intercom('show');
      },
      Icon: IoChatbubblesOutline,
    },
    {
      label: t('Weiterempfehlungen'),
      to: '/dashboard/referral',
      Icon: AiOutlineUsergroupAdd,
    },
  ];

  if (!isCompany) {
    upperLinks.push({
      label: t('RisikoCockpit'),
      href: riskcockpitUrl,
      Icon: IoCashOutline,
    });
  }

  const lowerLinks = [
    {
      label: t('Mitteilungen'),
      to: '/dashboard/notifications',
    },
    {
      label: t('Meine Dokumente'),
      to: '/dashboard/user-uploads',
    },
    {
      label: t('Profil bearbeiten'),
      to: '/dashboard/profile',
    },
    {
      label: t('Dein Maklermandat'),
      to: '/dashboard/mandate',
    },
    {
      label: t('Dokument hochladen'),
      to: '/dashboard/user-uploads/create',
    },
    {
      label: t('Abmelden'),
      onClick: async () => {
        await firebase.auth().signOut();
        // props.toggleDrawer('left');
      },
    },
  ];

  return (
    <ResponsiveContext.Consumer>
      {size =>
        size === 'small' ? (
          <Box fill>
            {
              <Box
                as='header'
                flex={false}
                overflow='visible'
                style={{ zIndex: 1 }}
              >
                <Header />
              </Box>
            }
            <Box as='main' background='white' overflow='scroll' fill>
              {props.children}
            </Box>
            <div className='bg-denim flex flex-row max-w-full'>
              {upperLinks.map(({ Icon, onClick, to, href }) =>
                onClick ? (
                  <button
                    key={to}
                    onClick={onClick}
                    className='p-4 flex-1 flex justify-center items-center outline-none'
                  >
                    <Icon size={25} color={arctic} />
                  </button>
                ) : (
                  <Link
                    key={to || href}
                    to={to || { pathname: href }}
                    className='p-4 flex-1 flex justify-center items-center outline-none'
                    target={href ? '_blank' : ''}
                  >
                    <Icon size={25} color={arctic} />
                  </Link>
                ),
              )}
            </div>
          </Box>
        ) : (
          <div className='h-screen flex flex-col'>
            <Header />
            <div className='flex flex-1 flex-row w-full overflow-auto 2xl:container mx-auto '>
              <div className='px-8 py-4 overflow-auto'>
                <Link to='/dashboard/profile'>
                  {image ? (
                    <div className='flex-grow-0'>
                      <img
                        className='w-32 h-32 rounded-full items-center justify-center mb-20 ml-2 object-cover'
                        src={image}
                        alt={fullName}
                      />
                    </div>
                  ) : (
                    <div className='flex-grow-0 w-32 h-32 rounded-full p-4 mb-20 ml-2 object-cover bg-lightgrey flex justify-center items-center'>
                      <span className='overflow-ellipsis text-xl text-center'>
                        {fullName}
                      </span>
                    </div>
                  )}
                </Link>
                <div className='flex flex-col items-start'>
                  {upperLinks.map(({ label, to, href, onClick, Icon }) => {
                    return (
                      <Button
                        key={label}
                        plain
                        color='text-denim'
                        to={to}
                        href={href}
                        onClick={onClick}
                        label={label}
                        icon={<Icon size={25} color={denim} />}
                        target={href ? '_blank' : ''}
                      />
                    );
                  })}
                </div>
                <div className='border-teal border-b my-6 ml-4' />
                <div className='flex flex-col items-start'>
                  {lowerLinks.map(({ label, to, onClick }) => {
                    return (
                      <Button
                        key={label}
                        plain
                        color='text-denim'
                        to={to}
                        onClick={onClick}
                        label={label}
                      />
                    );
                  })}
                </div>
                <div className='my-6 ml-4'>
                  <LanguageSwitcher />
                </div>
              </div>
              <div
                id='dashboardContainer'
                className='flex flex-1 flex-col overflow-y-auto pb-16'
              >
                {props.children}
              </div>
              <ScrollToTop elementID='dashboardContainer' />
            </div>
          </div>
        )
      }
    </ResponsiveContext.Consumer>
  );
}

export default DashboardLayout;
