import firebase from 'firebase/compat/app';
import { convertQuerySnapshot } from 'firebase-helpers';
import { logError } from '../utils/logging';

export function listenToCategories(handleNewData) {
  return firebase
    .firestore()
    .collection('categories')
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData([]);
      } else {
        const data = convertQuerySnapshot(snapshot).filter(
          entry => !entry.deleted,
        );
        handleNewData(data);
      }
    }, logError);
}
