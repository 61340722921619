import { createContext } from 'react';

export const ProfileContext = createContext(null);
export const UserDataContext = createContext({
  policies: null,
  notifications: null,
  offers: null,
  insurers: null,
  referrals: null,
  categories: null
});
