import React from 'react';
import { Box, Text, Form } from 'grommet';
import firebase from 'firebase/compat/app';
import { connect } from 'react-redux';
import { getUserInfo, setUser } from '../../store/actions/index';
import { Link, withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import InputButtonCombination from '../InputButtonCombination';
import { shortLanguage } from '../../i18n';
import { logError } from '../../utils/logging';

const mapDispatchToProps = dispatch => ({
  getUserInfo: user => dispatch(getUserInfo(user)),
  setUser: user => dispatch(setUser(user)),
});

const mapStateToProps = state => ({
  signedIn: state.user.signedIn,
  user: state.user,
});

class EmailAuth extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isLoading: false,
      emailWasSent: false,
      error: null,
    };
  }

  componentDidMount() {
    firebase.auth().languageCode = shortLanguage;
  }

  submitEmail = async () => {
    // See https://firebase.google.com/docs/auth/web/email-link-auth
    this.setState({ isLoading: true });
    try {
      await firebase.auth().sendSignInLinkToEmail(this.state.email, {
        // URL you want to redirect back to. The domain for this
        // URL must be whitelisted in the Firebase Console.
        url: `${window.location.origin}/finish-sign-in`,
        handleCodeInApp: true, // Must be true.
      });
      // The link was successfully sent. Inform the user.
      this.setState({ emailWasSent: true, error: null });
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', this.state.email);
    } catch (e) {
      logError(e);
      this.setState({
        error: e.message,
        email: '',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <Translation>
        {t =>
          this.state.emailWasSent ? (
            <Box align='center'>
              <Text textAlign='center'>
                {t('linkSentTo', { email: this.state.email })}
              </Text>
            </Box>
          ) : (
            <Form onSubmit={this.submitEmail}>
              <div className='flex flex-col justify-between'>
                <h3 className='text-denim mb-20 leading-tight'>
                  {t('Melde dich mit deiner E-Mail an.')}
                </h3>
                <InputButtonCombination
                  inputProps={{
                    type: 'email',
                    placeholder: 'E-Mail',
                    onChange: e => this.setState({ email: e.target.value }),
                  }}
                  buttonProps={{
                    isLoading: this.state.isLoading,
                    disabled: this.state.isLoading,
                    label: t('Link anfordern'),
                    onClick: this.submitEmail,
                  }}
                />
                {this.state.error && (
                  <Text color='status-error'>
                    {t(
                      'Die E-Mail konnte nicht gesendet werden. Haben Sie Ihre Adresse richtig eingegeben?',
                    )}
                  </Text>
                )}
                <Link
                  className='mt-8'
                  to={`${this.props.history.location.pathname}?type=personal`}
                >
                  {t('Kein Geschäftskunde? Dann klicke hier.')}
                </Link>
              </div>
            </Form>
          )
        }
      </Translation>
    );
  }
}

const AuthWithTranslation = withTranslation()(EmailAuth);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AuthWithTranslation));
