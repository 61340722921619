import { Trans, useTranslation } from 'react-i18next';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import { logError } from '../../utils/logging';
import Button from '../Button';
import { toast } from 'react-hot-toast';
import ChangeEmail from './ChangeEmail';
import AuthLayout from '../../layouts/AuthLayout';

export default function VerifyEmail({ history }) {
  const { t } = useTranslation();
  const { currentUser } = getAuth() || {};

  const countdownLength = 60; // seconds

  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [allowResend, setAllowResend] = useState(false);
  const [countdown, setCountdown] = useState(countdownLength);
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);

  const sendEmail = async () => {
    try {
      setLoading(true);
      await firebase
        .auth()
        .currentUser.sendEmailVerification({ url: 'https://app.caveo.ch' });
      setHasSentEmail(true);
      setAllowResend(false); // Triggers a disabling of the resend button and starting of the counter
    } catch (e) {
      const errorStrings = {
        'auth/too-many-requests':
          'You have requested too many verification e-mails. Please try again later.',
      };
      if (!errorStrings[e.code]) {
        logError(e);
      }
      toast.error(errorStrings[e.code] || e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasSentEmail) {
      // Resets the counter, which will be restarted after the user sends another verification mail
      if (allowResend) {
        setCountdown(countdownLength);
      } else if (countdown === 0) {
        // If the counter reaches 0, allow the user to resend a verification email
        setAllowResend(true);
      }
    }
  }, [allowResend, countdown, hasSentEmail]);

  useEffect(() => {
    if (hasSentEmail && !allowResend && countdown > 0) {
      const counter = setInterval(() => setCountdown(c => c - 1), 1000);
      return () => clearInterval(counter);
    }
  }, [allowResend, countdown, hasSentEmail]);

  const changeEmailButton = (
    <Button
      secondary
      onClick={async () => setVisible(true)}
      isLoading={isLoading}
    >
      {t('E-Mail Adresse ändern')}
    </Button>
  );

  return (
    <AuthLayout>
      <h1>{t('E-Mail verifizieren.')}</h1>
      <div className='flex flex-col space-y-8 items-center justify-center mt-4'>
        <div className='flex flex-col space-y-2'>
          <p className='text-lg'>
            <Trans
              defaults={
                hasSentEmail
                  ? 'Wir haben eine E-Mail an <bold>{{ email }}</bold> gesendet. Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen, um Ihre E-Mail-Adresse zu bestätigen.'
                  : 'Caveo möchte Ihre E-Mail verifizieren. Klicken Sie auf die Schaltfläche <bold>Verifizierungsmail senden</bold>, um einen Verifizierungslink an <bold>{{ email }}</bold> zu senden.'
              }
              components={{
                bold: <b />,
              }}
              values={{ email: currentUser?.email }}
            />
          </p>
          {hasSentEmail && (
            <div className='flex flex-col space-y-2'>
              <div className='flex items-center space-x-4'>
                <p>{t('Sie haben die E-Mail nicht erhalten?')}</p>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading || hasSentEmail ? !allowResend : false}
                  onClick={() =>
                    currentUser?.reload().then(() => {
                      if (!currentUser?.emailVerified) {
                        sendEmail();
                      }
                    })
                  }
                >
                  {allowResend
                    ? t('Nochmals senden')
                    : t('resendEmailIn', {
                        countdown: countdown,
                      })}
                </Button>
              </div>
              <div className='flex items-center space-x-4'>
                <p>{t('Haben wir an die falsche Adresse geschickt?')}</p>
                {changeEmailButton}
              </div>
            </div>
          )}
        </div>
        <div className='w-full flex flex-col sm:flex-row sm:justify-cente items-start sm:items-center space-y-4 sm:space-x-4 sm:space-y-0'>
          <Button
            onClick={() =>
              currentUser?.reload().then(async () => {
                if (hasSentEmail) {
                  if (currentUser?.emailVerified) {
                    return history.push(`/${window.location.search}`);
                  }
                  toast.error(
                    t(
                      'Sie haben Ihre E-Mail nicht verifiziert. Bitte versuchen Sie es erneut.',
                    ),
                  );
                } else if (!currentUser?.emailVerified) {
                  await sendEmail();
                }
              })
            }
            isLoading={isLoading}
          >
            {hasSentEmail
              ? t('Ich habe meine E-Mail verifiziert')
              : t('Bestätigungsmail senden')}
          </Button>
          {!hasSentEmail && changeEmailButton}
        </div>
      </div>
      <ChangeEmail isVisible={isVisible} onClose={() => setVisible(false)} />
    </AuthLayout>
  );
}
