import React from 'react';
import DashboardContainer from './DashboardContainer';
import Greeting from './Greeting';
import Policies from './Policies';
import NotificationsList from '../notifications/NotificationsList';
import CostChart from './CostChart';

function Dashboard(props) {
  return (
    <div className='p-4 sm:p-8 space-y-4'>
      <div className='flex flex-col xl:flex-row space-y-4 space-x-0 lg:space-y-0 lg:space-x-4'>
        <div className='xl:w-2/3 pr-4 pb-4'>
          <Greeting />
        </div>
        <DashboardContainer className='xl:w-1/3'>
          <CostChart />
        </DashboardContainer>
      </div>
      <div className='flex flex-col xl:flex-row space-y-4 space-x-0 lg:space-y-0 lg:space-x-4'>
        <DashboardContainer className='xl:w-2/3'>
          <Policies showAddButton includeCompanies />
        </DashboardContainer>
        <DashboardContainer className='xl:w-1/3'>
          <NotificationsList teaserOnly />
        </DashboardContainer>
      </div>
    </div>
  );
}

export default Dashboard;
