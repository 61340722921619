import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'grommet';
import firebase from 'firebase/compat/app';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { useTranslation, withTranslation } from 'react-i18next';
import CaveoLoader from '../../components/CaveoLoader';
import SelectableCard from '../../components/SelectableCard';
import Button from '../../components/Button';
import { ProfileContext, UserDataContext } from '../../contexts';
import { updateUser } from '../../api/user';
import { useHistory } from 'react-router-dom';

const LightTealInput = props => (
  <input
    className='bg-lightteal p-4 pl-0 font-serif text-2xl mb-8 mt-4'
    {...props}
  />
);

function ChooseCompanies(props) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const { insurers } = useContext(UserDataContext);
  const history = useHistory();

  const { onboardingProgress, isCompany } = profile || {};

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [customCompanies, setCustomCompanies] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [getInTouch, toggleGetInTouch] = useState(false);
  const [isLoading, toggleLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      setSelectedCompanies(profile.companies || []);
      setCustomCompanies(profile.customCompanies || '');
      toggleGetInTouch(profile.getInTouch || false);
    }
  }, [profile]);

  const saveAndContinue = async () => {
    toggleLoading(true);
    const nextStep = isCompany ? 'mandate-announcer' : 'pension-plan';
    const body = {
      onboardingProgress: nextStep,
      companies: selectedCompanies,
      customCompanies,
      // noInsurances: noInsurances,
      getInTouch,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await updateUser(body);
    history.push(`/onboarding/${nextStep}`);
  };

  if (isLoading || !insurers || !profile) {
    return <CaveoLoader />;
  }

  return (
    <OnboardingLayout
      backgroundColor='bg-lightteal'
      step='choose-companies'
      onboardingProgress={onboardingProgress}
    >
      <div className='lg:mr-8 lg:w-1/3'>
        <h1 className='text-denim text-2xl'>
          {t('Wähle alle Versicherungen aus, bei denen du derzeit Kunde bist.')}
        </h1>
        <LightTealInput
          onChange={e => setSearchTerm(e.target.value)}
          placeholder={t('Suchen')}
        />
      </div>
      <div className='flex flex-col flex-1 items-stretch'>
        <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
          {insurers
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(c =>
              c.name.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            .map(company => {
              const { id, logo, name } = company;
              return (
                <SelectableCard
                  key={id}
                  onClick={() =>
                    setSelectedCompanies(array =>
                      array.includes(id)
                        ? array.filter(e => e === id)
                        : [...array, id],
                    )
                  }
                  isSelected={selectedCompanies.includes(id)}
                >
                  <Image fit={'contain'} src={logo && logo.src} alt={name} />
                </SelectableCard>
              );
            })}
        </div>
        {
          // <SelectableCard
          //   onClick={() =>
          //     this.setState(state => ({
          //       noInsurances: !state.noInsurances,
          //     }))
          //   }
          //   isSelected={noInsurances}
          // >
          //   <p>{t('')}</p>
          // </SelectableCard>
        }
        <SelectableCard
          onClick={() => toggleGetInTouch(v => !v)}
          isSelected={getInTouch}
          className='w-full mt-4'
        >
          <p className='text-denim text-lg'>
            {t('Ich hab keine Ahnung, wo ich überall versichert bin')}
          </p>
        </SelectableCard>
        <LightTealInput
          onChange={e => setCustomCompanies(e.target.value)}
          placeholder={t('Weitere Versicherung')}
        />
        <Button
          primary
          className='lg:self-end'
          backgroundColor='bg-denim'
          label={t('Erledigt')}
          disabled={
            selectedCompanies.length === 0 &&
            customCompanies.length === 0 &&
            !getInTouch
            // && !noInsurances
          }
          isLoading={isLoading}
          onClick={saveAndContinue}
        />
      </div>
    </OnboardingLayout>
  );
}

export default withTranslation()(ChooseCompanies);
