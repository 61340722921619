const firebaseConfig = {
  apiKey: 'AIzaSyD2DvMjFKtNOj9RpbXF7NrrC73FXxkhORo',
  authDomain: 'caveo-5ed92.firebaseapp.com',
  databaseURL: 'https://caveo-5ed92.firebaseio.com',
  projectId: 'caveo-5ed92',
  storageBucket: 'gs://caveo-5ed92.appspot.com/',
  messagingSenderId: '6956939098',
  appId: '1:6956939098:web:6abee8022bfa588151a563',
  measurementId: 'G-HW5G0Q08EH',
};
// const firebaseConfig = {
//   apiKey: 'AIzaSyBWNykil343amfALuH_mGpLX17uusDdZQA',
//   authDomain: 'caveo-staging-cc31b.firebaseapp.com',
//   projectId: 'caveo-staging-cc31b',
//   storageBucket: 'caveo-staging-cc31b.appspot.com',
//   messagingSenderId: '462347743956',
//   appId: '1:462347743956:web:8caaabd3c63ba23a748141',
// };

export default firebaseConfig;
