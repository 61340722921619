import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Form } from 'react-final-form';
import firebase from 'firebase/compat/app';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { updateUser } from '../../api/user';
import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { logError } from '../../utils/logging';
import Button from '../Button';
import Modal from '../Modal';
import { ProfileContext } from '../../contexts';
import { isEmpty } from 'lodash';

export default function ChangeEmail({ isVisible, onClose }) {
  const { t } = useTranslation();

  const profile = useContext(ProfileContext);

  const [isLoading, setLoading] = useState(false);

  const auth = firebase.auth();
  const user = auth.currentUser;

  const initialValues = {
    oldEmail: user?.email,
    password: '',
    newEmail: '',
  };

  const isEmailValid = email =>
    Yup.string()
      .email()
      .isValidSync(email);

  const changeEmail = async ({ oldEmail, password, newEmail }) => {
    setLoading(true);
    try {
      if (!isEmailValid(oldEmail) || !isEmailValid(newEmail)) {
        return toast.error(t('Bitte gib eine gültige E-Mail-Adresse ein.'));
      }
      // Re-authenticate with current email because updating email requires a recent log in
      const oldCredential = EmailAuthProvider.credential(oldEmail, password);
      await reauthenticateWithCredential(user, oldCredential);
      await user.updateEmail(newEmail);
      // Update the user doc if it exists
      if (!isEmpty(profile)) {
        updateUser(user.uid, { email: newEmail });
      }
      onClose();
    } catch (error) {
      // Errors that don't need to be logged to crashlytics
      const errorStrings = {
        'auth/wrong-password': t('components//PhoneAuth::wrongPassword'),
        'auth/email-already-in-use': t(
          'Diese E-Mail gehört zu einem bestehenden Benutzer.',
        ),
      };
      if (!errorStrings[error.code]) {
        logError(error);
      }
      toast.error(errorStrings[error.code] || error.message);
    } finally {
      setLoading(false);
    }
  };

  const inputClasses =
    'bg-lightteal rounded-full font-serif w-full text-lg border sm:border-0 border-teal lg:mr-4 text-denim focus:outline-none outline-none ring-0 focus:ring-0 focus:ring-teal focus:border-teal sm:focus:border-none placeholder-teal';

  return (
    <Modal isVisible={isVisible} close={onClose}>
      <div className='mx-auto p-8 space-y-4'>
        <p className='font-bold text-lg'>
          {t('Ändern Sie die E-Mail-Adresse Ihres Kontos')}
        </p>
        <Form onSubmit={changeEmail} initialValues={initialValues}>
          {({ handleSubmit, values }) => {
            const { oldEmail, password, newEmail } = values;
            const disabled = !oldEmail || !newEmail || !password;
            return (
              <form className='space-y-4'>
                <p>{t('Aktuelle E-Mail')}</p>
                <Field
                  name='oldEmail'
                  placeholder={t('Alte E-Mail')}
                  component='input'
                  className={inputClasses}
                  required={true}
                  disabled={true}
                />
                <p>{t('Geben Sie Ihr Passwort ein')}</p>
                <Field
                  name='password'
                  type='password'
                  placeholder={t('Passwort')}
                  component='input'
                  className={inputClasses}
                  required={true}
                />
                <p>{t('Gib deine neue E-Mail ein')}</p>
                <Field
                  name='newEmail'
                  placeholder={t('Neue E-Mail')}
                  component='input'
                  className={inputClasses}
                  required={true}
                />
                <Button
                  isLoading={isLoading}
                  disabled={disabled}
                  onClick={handleSubmit}
                  label={t('Weiter')}
                />
              </form>
            );
          }}
        </Form>
      </div>
    </Modal>
  );
}
