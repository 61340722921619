import firebase from 'firebase/compat/app';
import { convertQuerySnapshot } from 'firebase-helpers';
import { logError } from '../utils/logging';

export function listenToPolicies(handleNewData) {
  return firebase
    .firestore()
    .collection('policies')
    .where('user', '==', firebase.auth().currentUser.uid)
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData([]);
      } else {
        const data = convertQuerySnapshot(snapshot).filter(
          entry => !entry.deleted,
        );
        // Remove inactive policies:
        handleNewData(data.filter(policy => policy.policyStatus !== 0));
      }
    }, logError);
}
