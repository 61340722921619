import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop({ elementID }) {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0); // Add this because the next line doesn't always work.
      const element = document.getElementById(elementID);
      if (element) {
        element.scrollTo(0, 0);
      }
    } else {
      setTimeout(() => {
        const hashElement = document.getElementById(hash.slice(1));
        if (hashElement) {
          document
            .getElementById(hash.slice(1))
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 250);
    }
  }, [pathname, hash, elementID]);
  return null;
}
