import { TRY_AUTH } from '../actions/actionTypes';

const initialState = {
  token: null,
  displayName: null,
  email: null,
  phoneNumber: null,
  photoURL: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TRY_AUTH:
      return {
        ...state,
        token: action.uid,
        displayName: action.displayName,
        email: action.email,
        phoneNumber: action.phoneNumber,
        photoURL: action.photoURL,
      };
    default:
      return state;
  }
};

export default reducer;
