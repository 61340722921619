import React from 'react';
import { Box, Image } from 'grommet';
import Logo from '../assets/logo.png';
import { arctic } from '../theme';
import ScaleLoader from 'react-spinners/ScaleLoader';

export default function FullScreenLoader(props) {
  return (
    <Box fill align='center' justify='center' background='white'>
      <Box height='xsmall' pad='small'>
        <Image src={Logo} fit='contain' />
      </Box>
      <ScaleLoader size={300} color={arctic} />
    </Box>
  );
}
