import firebase from 'firebase/compat/app';
import { Close } from 'grommet-icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProfileContext } from '../contexts';
import StoreBadges from './StoreBadges';
import { denim } from '../theme';
import Button from './Button';
import LanguageSwitcher from './LanguageSwitcher';
import postEventsToMeta from '../utils/postEventsToMeta';
// import { ReactComponent as Menu } from '../assets/icons/menu.svg';

function MobileMenu({ toggleMenu, type, reduced }) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const signedIn = !!firebase.auth().currentUser;
  const history = useHistory();

  const buttons = [
    {
      label: t('Privatkunde'),
      value: 'personal',
      onClick: () => {
        history.push(`${history.location.pathname}?type=personal`);
        toggleMenu(false);
      },
    },
    {
      label: t('Geschäftskunde'),
      value: 'business',
      onClick: () => {
        history.push(`${history.location.pathname}?type=business`);
        toggleMenu(false);
      },
    },
  ];

  const typeButtons = reduced ? (
    <Button secondary to={'/'} label={t('Registrieren')} />
  ) : (
    buttons.map(({ label, value, ...rest }) => (
      <div key={label} className='mr-2 inline-block'>
        <Button
          plain={type !== value}
          secondary={type === value}
          label={label}
          {...rest}
        />
      </div>
    ))
  );

  const makeAppointmentButton = (
    <Button
      onClick={async () => {
        const consultant = await firebase
          .firestore()
          .collection('users')
          .doc(profile.consultant)
          .get()
          .then(snap => snap.data());
        const { calendarLink } = consultant || {};
        const fallbackLink =
          'https://outlook.office365.com/owa/calendar/CaveoTeam@caveo.ch/bookings/';
        firebase.analytics().logEvent('appointment_link_opened');
        // Send events to facebook API
        const eventsData = {
          userEmail: profile.email,
          userPhoneNumber: profile.phoneNumber,
          eventName: 'appointment_link_opened',
          customData: { consultant: profile.consultant },
          action_source: 'website',
        };
        await postEventsToMeta(eventsData);
        window.open(calendarLink || fallbackLink, '_blank');
      }}
      secondary
      label={t('Termin vereinbaren')}
    />
  );

  const signedInLinks = [
    {
      label: t('Mitteilungen'),
      to: '/dashboard/notifications',
    },
    {
      label: t('Meine Dokumente'),
      to: '/dashboard/user-uploads',
    },
    {
      label: t('Profil bearbeiten'),
      to: '/dashboard/profile',
    },
    {
      label: t('Dein Maklermandat'),
      to: '/dashboard/mandate',
    },
    {
      label: t('Dokument hochladen'),
      to: '/dashboard/user-uploads/create',
    },
    {
      label: t('Abmelden'),
      onClick: async () => {
        await firebase.auth().signOut();
      },
    },
  ];

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 bg-white dark:bg-denim p-4 z-50'>
      <div className='justify-between items-center flex'>
        <div className='justify-around items-center flex'>
          {signedIn ? makeAppointmentButton : typeButtons}
        </div>
        <Close color={denim} onClick={() => toggleMenu(false)} />
      </div>
      <div className='mt-8 flex flex-col items-start'>
        {signedInLinks.map(({ label, to, onClick }) => {
          return (
            <Button
              className='text-lg'
              key={label}
              plain
              color='text-denim'
              to={to}
              onClick={onClick}
              label={label}
            />
          );
        })}
      </div>
      <div className='p-4 mt-8'>
        <LanguageSwitcher />
      </div>
      <div className='p-4'>
        <StoreBadges />
      </div>
    </div>
  );
}

export default MobileMenu;
