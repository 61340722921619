import React, { useContext } from 'react';
import ProfileForm from '../../components/profile/ProfileForm';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../contexts';

const mapState = state => ({
  lastChanged: state.user.lastChanged,
  isCompany: state.user.isCompany,
});

function Profile(props) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  return (
    <div className='p-8'>
      <h1 className='text-denim mb-8'>{t('Profil')}</h1>
      <ProfileForm
        onboarding={false}
        isCompany={!!profile.isCompany}
        buttonLabel={t('Speichern')}
        key={props.lastChanged} // Setting the key allows to "reset" the component. See: https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
      />
    </div>
  );
}

export default connect(mapState)(Profile);
