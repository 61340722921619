import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { sea } from '../theme';
import { Box } from 'grommet';

function CaveoLoader(props) {
  if (!props.isLoading) {
    return null;
  }
  if (props.overlay) {
    return (
      <Box
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
        justify='center'
        align='center'
        background={{ color: 'white', opacity: 'strong' }}
      >
        <ScaleLoader color={sea} {...props} />
      </Box>
    );
  }
  return <ScaleLoader color={sea} {...props} />;
}

export default CaveoLoader;
