import React from 'react';
import { IoIosClose } from 'react-icons/io';
import { denim } from '../theme';
import classnames from 'classnames';
import { Layer } from 'grommet';

function Modal({ isVisible, close, children, backgroundColor }) {
  if (!isVisible) {
    return null;
  }

  return (
    <Layer onClickOutside={close} onEsc={close}>
      <div
        className={classnames(
          'border-0 rounded-lg shadow-lg relative flex flex-col-reverse sm:flex-row w-full outline-none focus:outline-none',
          backgroundColor || 'bg-white',
        )}
      >
        <div className='relative p-6 flex-auto'>{children}</div>
        <div className='flex flex-col items-end sm:items-start justify-between p-6 rounded-t'>
          <button onClick={close}>
            <IoIosClose color={denim} className='text-denim' size='30' />
          </button>
        </div>
      </div>
    </Layer>
  );
}

export default Modal;
