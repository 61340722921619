import React from 'react';
import omit from 'lodash/omit';
import loadImage from 'blueimp-load-image';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ChangeableAvatar from './ChangeableAvatar';
import FlatButton from '../FlatButton';
import { ProfileContext } from '../../contexts';
import GenderSelect from './GenderSelect';
import classnames from 'classnames';

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.fileInput = React.createRef();
  }

  static contextType = ProfileContext;

  componentDidMount() {
    moment().locale(this.context.language);
  }

  addressFields = [
    {
      name: 'street',
      label: this.props.t('Strasse'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'number',
      label: this.props.t('Hausnummer'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'zipCode',
      label: this.props.t('PLZ'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'city',
      label: this.props.t('Ort'),
      Component: <input type='text' />,
      required: true,
    },
  ];

  personalFields = [
    {
      name: 'gender',
      label: this.props.t('Anrede'),
      Component: <GenderSelect />,
      required: true,
    },
    {
      name: 'firstName',
      label: this.props.t('Vorname'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'lastName',
      label: this.props.t('Nachname'),
      Component: <input type='text' />,
      required: true,
    },
    ...this.addressFields,
    {
      name: 'email',
      label: this.props.t('E-Mail-Adresse'),
      Component: <input type='email' />,
      required: true,
    },
    {
      name: 'birthday',
      label: this.props.t('Geburtstag'),
      Component: <input type='date' />,
      required: true,
    },
  ];

  companyFields = [
    {
      name: 'companyName',
      label: this.props.t('Firma'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'industry',
      label: this.props.t('Branche'),
      Component: <input type='text' />,
      required: true,
    },
    ...this.addressFields,
    {
      name: 'phoneNumber',
      label: this.props.t('Telefonnummer'),
      Component: <input type='text' />,
      required: true,
    },
  ];

  companyContactFields = [
    {
      name: 'contactGender',
      label: this.props.t('Anrede'),
      Component: <GenderSelect />,
      required: true,
    },
    {
      name: 'contactFirstName',
      label: this.props.t('Vorname'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'contactLastName',
      label: this.props.t('Nachname'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'contactPhone',
      label: this.props.t('Telefonnummer'),
      Component: <input type='text' />,
      required: true,
    },
    {
      name: 'contactEmail',
      label: this.props.t('E-Mail'),
      Component: <input type='email' />,
      required: true,
    },
  ];

  Field = ({ label, name, Component, ...rest }) => (
    <label
      className={classnames(
        'flex flex-row items-center border-teal border-t-0 border-r-0 border-l-0 py-2',
        this.props.onboarding ? 'border-0' : 'border mb-2',
      )}
    >
      {!this.props.onboarding && (
        <span className='mr-2 w-40 font-normal' htmlFor={name}>
          {label}
        </span>
      )}
      {React.cloneElement(Component, {
        className: classnames(
          'mt-0 block w-full px-0.5 border-0 focus:ring-0 focus:border-denim font-normal text-denim',
          this.props.onboarding ? 'bg-arctic' : 'bg-white',
          this.props.onboarding ? 'font-serif text-xl' : 'font-sans',
        ),
        autoComplete: true,
        name,
        placeholder: label,
        onChange: this.handleChange,
        value: this.state[name] || this.context[name] || '',
        ...rest,
      })}
    </label>
  );

  getBirthday = () =>
    moment()
      .year(this.state.yearOfBirth || this.context.yearOfBirth)
      .month(this.state.monthOfBirth || this.context.monthOfBirth) // Value in state is an index that starts at 0
      .date(this.state.dayOfBirth || this.context.dayOfBirth)
      .format('YYYY-MM-DD');

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    // See: https://nsulistiyawan.github.io/2016/07/11/Fix-image-orientation-with-Javascript.html#Include-that-library-in-your-html-head
    loadImage.parseMetaData(file, data => {
      var orientation = 0;
      if (data.exif) {
        orientation = data.exif.get('Orientation');
      }
      loadImage(
        file,
        canvas => {
          const base64data = canvas.toDataURL('image/jpeg');
          this.setState({
            image: {
              file: file,
              src: base64data,
            },
          });
        },
        {
          // should be set to canvas : true to activate auto fix orientation
          canvas: true,
          orientation: orientation,
          maxWidth: 1000,
          maxHeight: 1000,
        },
      );
    });
  };

  uploadImage = async () => {
    this.setState({ isLoading: true });
    const storage = firebase.storage();
    const storageRef = storage.ref(
      `avatars/${firebase.auth().currentUser.uid}_${
        this.state.image.file.name
      }`,
    );
    return storageRef.putString(this.state.image.src, 'data_url').then(
      snapshot => {
        this.setState({ isLoading: false });
        return snapshot.ref.getDownloadURL();
      },
      error => this.setState({ isLoading: false }),
    );
  };

  handleSubmit = async event => {
    this.setState({ isLoading: true });
    let changedValues = omit(this.state, 'isLoading');
    if (!this.props.isCompany) {
      // Was a birthday component changed?
      const { dayOfBirth, monthOfBirth, yearOfBirth } = changedValues;
      if (dayOfBirth || monthOfBirth || yearOfBirth) {
        changedValues.birthday = this.getBirthday();
        delete changedValues.dayOfBirth;
        delete changedValues.monthOfBirth;
        delete changedValues.yearOfBirth;
      }
    }
    // Was the image changed?
    let downloadPath;
    if (this.state.image && this.state.image.src) {
      downloadPath = await this.uploadImage();
      changedValues.image = downloadPath;
    }
    // Add the server timestamp:
    changedValues.modified = firebase.firestore.FieldValue.serverTimestamp();
    const body = {
      ...changedValues,
      language: this.context.language,
    };
    if (this.props.isCompany) {
      body.email = this.context.email;
    } else {
      body.phoneNumber = this.context.phoneNumber;
    }
    // Write to database:
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .set(body, { merge: true });
    this.setState({ isLoading: false });
  };

  isButtonDisabled() {
    const fieldsState = omit(this.state, 'isLoading');
    // If the user has not entered or changed anything yet,
    // disable the button:
    if (
      Object.keys(fieldsState).length === 0 &&
      this.context.didFinishOnboarding
    ) {
      return true;
    }
    // If we don't have a value for the required fields
    // neither in state nor in context, disable the button:
    for (const key in this.requiredFields) {
      if (this.requiredFields.hasOwnProperty(key)) {
        if (!this.state[key] && !this.context[key]) {
          return true;
        }
      }
    }
    // If one of the values in state is different than
    // the one in context, enable the button:
    for (const key in fieldsState) {
      if (fieldsState.hasOwnProperty(key)) {
        if (this.props[key] !== fieldsState[key]) {
          return false;
        }
      }
    }
    // If we have no values for the required fields in context,
    // let the user save her input. If we do have values,
    // it means the user didn't change anything, therefore
    // we disable the button.
    for (const key in this.requiredFields) {
      if (this.requiredFields.hasOwnProperty(key)) {
        if (!this.context[key]) {
          return false;
        }
      }
    }
    // Make saving possible if the user has not yet finished the onboarding process.
    // Else, disabled it.
    return this.context.didFinishOnboarding;
  }

  getImage() {
    if (this.state.image) {
      return this.state.image.src;
    }
    if (this.state.image === null) {
      return null;
    }
    return this.context.image;
  }

  renderCompanyForm(size) {
    return (
      <div>
        {!this.props.onboarding && (
          <ChangeableAvatar
            image={this.getImage()}
            handleChange={this.handleImageChange}
            clearImage={() => this.setState({ image: null })}
          />
        )}
        {this.companyFields.map(field => (
          <this.Field {...field} key={field.name} />
        ))}
        <h3 className='my-8'>{this.props.t('Kontaktperson')}</h3>
        {this.companyContactFields.map(field => (
          <this.Field {...field} key={field.name} />
        ))}
      </div>
    );
  }

  renderPrivateForm() {
    return (
      <div>
        {!this.props.onboarding && (
          <ChangeableAvatar
            image={this.getImage()}
            handleChange={this.handleImageChange}
            clearImage={() => this.setState({ image: null })}
          />
        )}
        {this.personalFields.map(field => (
          <this.Field {...field} key={field.name} />
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className='max-w-xl'>
        {this.props.isCompany
          ? this.renderCompanyForm()
          : this.renderPrivateForm()}
        <div className='mt-8'>
          <FlatButton
            primary
            isLoading={this.state.isLoading}
            disabled={this.isButtonDisabled()}
            type='submit'
            label={this.props.buttonLabel}
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProfileForm);
