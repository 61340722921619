import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import { Box, Heading, Image, Text } from 'grommet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncButton from '../../components/AsyncButton';
import identification from '../../assets/sample-id.jpg';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { Translation } from 'react-i18next';
import ExifImageUploader from '../../components/ExifImageUploader';
import { setUser } from '../../store/actions';
import { logError } from '../../utils/logging';

const mapStateToProps = state => ({
  userID: state.auth.token,
  gender: state.user.gender,
});

const mapDispatchToProps = dispatch => ({
  setUser: data => dispatch(setUser(data)),
});

class UploadIdentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      isLoading: false,
    };
  }

  onChange = imageData => {
    if (!imageData) {
      return this.setState({ image: null });
    }
    this.setState({ image: imageData });
  };

  uploadImage = async () => {
    const nextStep = 'referrer';
    this.setState({ isLoading: true });
    const storage = firebase.storage();
    const storageRef = storage.ref(`identification/${this.props.userID}`);
    await storageRef.putString(this.state.image.src, 'data_url').then(
      async snapshot => {
        const downloadURL = await snapshot.ref.getDownloadURL();
        const db = firebase.firestore();
        const body = {
          onboardingProgress: nextStep,
          identification: {
            src: downloadURL,
            title: 'identification.jpg',
          },
        };
        return db
          .collection('users')
          .doc(this.props.userID)
          .set(
            {
              ...body,
              modified: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true },
          )
          .then(() => {
            this.props.setUser(body);
            this.props.history.push(`/onboarding/${nextStep}`);
          });
      },
      error => logError(error),
    );
    this.setState({ isLoading: false });
  };

  getImageSource = () => {
    if (this.state.image) {
      return this.state.image.src;
    }
    return identification;
  };

  render() {
    return (
      <Translation>
        {t => (
          <OnboardingLayout
            steps={[
              { icon: 'MdLockOpen', fulfilled: true },
              { icon: 'MdDashboard', fulfilled: true },
              { icon: 'FaPen', fulfilled: true },
              { icon: 'MdVerifiedUser', fulfilled: false },
            ]}
            step='upload-identification'
            onboardingProgress={this.props.onboardingProgress}
          >
            <Box flex={false} gap='medium' pad='large'>
              <Heading textAlign='center' level='1' color='neutral-1'>
                {t('onboarding//UploadIdentification::heading')}
              </Heading>
              <Text textAlign='center'>
                {t('onboarding//UploadIdentification::lead')}
              </Text>
              <Box pad='medium' height='medium'>
                <Image fill fit='contain' src={this.getImageSource()} />
              </Box>
              <Box width='large' align='center'>
                <ExifImageUploader
                  onChange={this.onChange}
                  label={t('onboarding//UploadIdentification::choosePhoto')}
                />
              </Box>
              {this.state.image && (
                <Box flex={false}>
                  <AsyncButton
                    primary
                    type='submit'
                    label={t('common//next')}
                    onClick={this.uploadImage}
                    isLoading={this.state.isLoading}
                  />
                </Box>
              )}
            </Box>
          </OnboardingLayout>
        )}
      </Translation>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UploadIdentification));
