import firebase from 'firebase/compat/app';
import {
  convertFirestoreTimestamps,
  convertQuerySnapshot,
} from 'firebase-helpers';
import { logError } from '../utils/logging';

/**
 * Fetch all companies from the backend.
 */
export const getInsurers = async () =>
  firebase
    .firestore()
    .collection('insurers')
    .orderBy('name', 'asc')
    .get()
    .then(convertQuerySnapshot);

export function listenToInsurers(handleNewData) {
  return firebase
    .firestore()
    .collection('insurers')
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData([]);
      } else {
        const data = convertQuerySnapshot(snapshot).filter(
          entry => !entry.deleted,
        );
        handleNewData(data);
      }
    }, logError);
}

/**
 * Fetch one company from the backend.
 */
export const getInsurer = async companyID =>
  firebase
    .firestore()
    .collection('insurers')
    .doc(companyID)
    .get()
    .then(snap => convertFirestoreTimestamps(snap.data()));
