import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CombinedTextFileField from '../../components/CombinedTextFileField';
import FlatButton from '../../components/FlatButton';
import SelectableCard from '../../components/SelectableCard';
import DashboardContainer from '../dashboard/DashboardContainer';

function RequestOffer(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedType, toggleType] = useState(null);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);

  const insuranceTypes = [
    'home',
    'motor',
    'travel',
    'pension',
    'legal',
    'health',
    'other',
  ];

  const sendRequest = async () => {
    setLoading(true);
    setError(null);
    try {
      // Upload file:
      let downloadUrl;
      if (file) {
        const reference = firebase
          .storage()
          .ref(
            `userUploads/${
              firebase.auth().currentUser.uid
            }/${new Date().toISOString()}_${file.name}`,
          );
        const uploadTask = reference.put(file, {
          cacheControl: 'max-age=31536000',
        });
        downloadUrl = await uploadTask.then(() => reference.getDownloadURL());
      }
      // Create task in CRM:
      const addTask = firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('addTask');
      const task = {
        type: 'newInsuranceRequested',
        user: firebase.auth().currentUser.uid,
        subject: `Versicherungsart: ${t(
          `insuranceTypes::short::${selectedType}`,
        )}`,
        info: message,
        insuranceType: selectedType,
      };
      if (downloadUrl) {
        task.files = [{ title: file.name, src: downloadUrl }];
      }
      const result = await addTask(task);
      const taskID = result.data;
      if (!taskID) {
        throw new Error('Could not create task.');
      }
      setLoading(false);
      // Clear data:
      setMessage('');
      setFile(null);
      // Log event:
      firebase.analytics().logEvent('offer_requested', { platform: 'web' });
      // Find out how the user wants to be contacted:
      history.push(`/dashboard/choose-answer-channel?taskID=${taskID}`);
    } catch (e) {
      setError(t('Nachricht konnte nicht gesendet werden.'));
      setLoading(false);
    }
  };

  return (
    <div className='p-8'>
      <h1 className='mb-8 text-denim'>{t('Neue Offerte anfordern')}</h1>
      <div className='grid sm:grid-cols-1 lg:grid-cols-2 gap-4'>
        <DashboardContainer className='p-4 grid sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {insuranceTypes.map(type => (
            <SelectableCard
              onClick={() => toggleType(type)}
              isSelected={type === selectedType}
            >
              <h5 className='text-denim my-8'>
                {t(`insuranceTypes::short::${type}`)}
              </h5>
            </SelectableCard>
          ))}
        </DashboardContainer>
        <div className='flex-1 w-full'>
          <CombinedTextFileField
            hideSendButton
            placeholder='Spezielle Wünsche angeben…'
            onTextChange={e => setMessage(e.target.value)}
            onFileChange={setFile}
            text={message}
            file={file}
          />
        </div>
      </div>
      <div className='flex flex-col items-end'>
        <FlatButton
          onClick={sendRequest}
          label={t('Neue Offerte anfordern')}
          isLoading={isLoading}
          disabled={!selectedType}
        />
        {error && <p className='text-red'>{error}</p>}
      </div>
    </div>
  );
}

export default RequestOffer;
