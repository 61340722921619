import React from 'react';
import { useTranslation } from 'react-i18next';

function MortgageCalculator() {
  const { i18n } = useTranslation();
  const language = i18n.language;
  return (
    <>
      <iframe
        src={`https://www.hypoteq.ch/calculator?UID=659CEF898CA92DA0DD26DADC85D2A5A6&lang=${language}`}
        id='hypoteqFrame'
        frameborder='0'
        style={{
          width: '1px',
          minWidth: '100%',
          minHeight: '100%',
        }}
        title='Hypotheken-Rechner'
      ></iframe>
      <script src='https://www.hypoteq.ch/calculatorWidget/dist/calc.init.js'></script>
    </>
  );
}

export default MortgageCalculator;
