import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { useTranslation } from 'react-i18next';
import FlatButton from '../../components/FlatButton';
import { updateUser } from '../../api/user';
import { useHistory } from 'react-router';

/**
 * A page that asks the user how he/she heard about Caveo.
 */
function Referrer(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [text, setText] = useState('');
  const [isLoading, setLoading] = useState(false);

  const saveAndContinue = async () => {
    const nextStep = 'finished';
    const body = {
      onboardingProgress: nextStep,
      referrer: text,
    };
    setLoading(true);
    try {
      await updateUser({
        ...body,
        modified: firebase.firestore.FieldValue.serverTimestamp(),
      });
      history.push(`/onboarding/${nextStep}`);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <OnboardingLayout backgroundColor='bg-flamingo'>
      <div className='lg:w-1/2 container mx-auto'>
        <h1 className='text-denim mb-8 leading-tight'>
          {t('Wie hast du von Caveo erfahren?')}
        </h1>
        <textarea
          className='rounded-md border-denim bg-flamingo w-full h-32 mb-8 text-denim'
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <FlatButton
          secondary
          label={t('Weiter')}
          onClick={saveAndContinue}
          type='submit'
          className='bg-denim text-white self-end'
          isLoading={isLoading}
        />
      </div>
    </OnboardingLayout>
  );
}

export default Referrer;
