import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { ProfileContext, UserDataContext } from '../../contexts';
import PolicyCard from './PolicyCard';
import CaveoLogo from '../../assets/logo.png';

function Policies({
  onPolicyClick,
  selectedPolicy,
  showAddButton,
  includeCompanies,
}) {
  const { t } = useTranslation();
  const { policies, insurers } = useContext(UserDataContext);
  const { companies } = useContext(ProfileContext);

  const [entries, setEntries] = useState(null);

  useEffect(() => {
    if (policies && companies && insurers) {
      const array = [...policies];
      if (includeCompanies) {
        // Add every company for which we have no policy:
        companies.forEach(id => {
          if (!policies.find(policy => policy.company === id)) {
            array.push({ company: id });
          }
        });
      }
      // Add the insurer to the policy to allow sorting alphabetically:
      const richEntries = array.map(entry => ({
        ...entry,
        insurer: insurers.find(i => i.id === entry.company) || {
          // In that case, we add Caveo as the company. // Some policies are saved by the client without a company.
          name: 'Caveo',
          logo: {
            title: 'Caveo',
            src: CaveoLogo,
          },
        },
      }));
      setEntries(richEntries);
    }
  }, [policies, companies, insurers, includeCompanies]);

  if (!entries) {
    return null;
  }

  return (
    <div className='p-4 grid grid-cols-2 lg:grid-cols-3 gap-4'>
      {entries.length > 0 ? (
        entries
          .sort((a, b) => {
            const nameA = a.insurer.name;
            const nameB = b.insurer.name;
            return nameA.localeCompare(nameB);
          })
          .map(entry => (
            <PolicyCard
              key={entry.id || entry.company}
              policy={entry}
              onClick={entry.id ? onPolicyClick : undefined}
              isSelected={entry.id && entry.id === selectedPolicy}
              disabled={!entry.id}
            />
          ))
      ) : (
        <p>{t('Du hast noch keine Versicherungen gewählt.')}</p>
      )}
      {showAddButton && (
        <Link className='h-40' to='/dashboard/request-offer'>
          <div className='p-4 h-full items-center justify-center flex flex-col shadow-lg hover:shadow-xl bg-teal rounded-xl '>
            <p className='text-white font-semibold text-center'>
              {t('Neue Offerte anfordern')}
            </p>
            <IoAdd color='white' size='2rem' className='mt-4' />
          </div>
        </Link>
      )}
    </div>
  );
}

export default Policies;
