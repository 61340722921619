import firebase from 'firebase/compat/app';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { ProfileContext } from '../contexts';
import Button from './Button';
import MobileMenu from './MobileMenu';
import classnames from 'classnames';
import postEventsToMeta from '../utils/postEventsToMeta';

function Header({ type, reduced }) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const signedIn = !!firebase.auth().currentUser;
  const { didFinishOnboarding } = profile || {};
  const dashboardView = signedIn && didFinishOnboarding;

  const [showMenu, toggleMenu] = useState(false);
  const [showShadow, toggleShadow] = useState(false);
  const history = useHistory();
  const prevPath = useRef();

  useEffect(() => {
    prevPath.current = history.location.pathname;
  }, [history.location.pathname]);

  if (prevPath.current !== history.location.pathname && showMenu) {
    toggleMenu(false);
  }

  const handleScroll = () => {
    if (window.scrollY > 0) {
      toggleShadow(true);
    } else {
      toggleShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll());
    return window.removeEventListener('scroll', e => handleScroll());
  }, []);

  return (
    <div
      className={classnames(
        'flex w-full justify-center top-0 dark:bg-denim bg-white z-10',
        { sticky: !showMenu },
        { 'shadow-md': showShadow },
      )}
    >
      <div className='2xl:container flex flex-row w-full justify-between items-center px-6 py-4 md:p-6'>
        <Link to='/'>
          <img src={Logo} alt='Caveo' className='h-6 sm:h-8 lg:h-10' />
        </Link>
        <div className='hidden lg:flex flex-row'>
          {dashboardView && (
            <Button
              onClick={async () => {
                const consultant = await firebase
                  .firestore()
                  .collection('users')
                  .doc(profile.consultant)
                  .get()
                  .then(snap => snap.data());
                const { calendarLink } = consultant || {};
                const fallbackLink =
                  'https://outlook.office365.com/owa/calendar/CaveoTeam@caveo.ch/bookings/';
                firebase.analytics().logEvent('appointment_link_opened');
                // Send events to facebook Api
                const eventsData = {
                  userEmail: profile.email,
                  userPhoneNumber: profile.phoneNumber,
                  eventName: 'appointment_link_opened',
                  customData: { consultant: profile.consultant },
                  action_source: 'website',
                };
                await postEventsToMeta(eventsData);
                window.open(calendarLink || fallbackLink, '_blank');
              }}
              secondary
              label={t('Termin vereinbaren')}
            />
          )}
        </div>
        <div className='lg:hidden flex flex-row items-center justify-center'>
          {
            // Menu icon:
            <svg
              className='h-6 w-6 fill-current text-denim dark:text-lightteal flex justify-center items-center'
              onClick={() => toggleMenu(true)}
            >
              <g stroke='none' strokeWidth='1' fillRule='evenodd'>
                <path
                  d='M27,8 C27.5522847,8 28,8.44771525 28,9 C28,9.55228475 27.5522847,10 27,10 L15,10 C14.4477153,10 14,9.55228475 14,9 C14,8.44771525 14.4477153,8 15,8 L27,8 Z M27,14 C27.5522847,14 28,14.4477153 28,15 C28,15.5522847 27.5522847,16 27,16 L5,16 C4.44771525,16 4,15.5522847 4,15 C4,14.4477153 4.44771525,14 5,14 L27,14 Z M27,20 C27.5522847,20 28,20.4477153 28,21 C28,21.5522847 27.5522847,22 27,22 L5,22 C4.44771525,22 4,21.5522847 4,21 C4,20.4477153 4.44771525,20 5,20 L27,20 Z'
                  fillRule='nonzero'
                />
              </g>
            </svg>
          }
          {showMenu && (
            <MobileMenu reduced={reduced} toggleMenu={toggleMenu} type={type} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
