import {
  TOGGLE_USER_STATUS,
  SET_USER,
  TRY_AUTH,
  SIGN_OUT,
} from '../actions/actionTypes';

const initialState = {
  signedIn: false,
  firstName: null,
  lastName: null,
  address: null,
  zipCode: null,
  place: null,
  mobilePhone: null,
  email: null,
  birthday: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TRY_AUTH: {
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    }
    case TOGGLE_USER_STATUS:
      return {
        ...state,
        signedIn: !state.signedIn,
      };
    case SET_USER:
      return {
        ...state,
        ...action.data,
        lastChanged: new Date(),
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
