import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { markNotificationsAsRead } from '../../api/notifications';
import PolicyOrOfferDetails from '../../components/PolicyOrOfferDetails';
import { UserDataContext } from '../../contexts';
import { useQuery } from '../../hooks';

function Offer() {
  const { offerID } = useParams();
  const query = useQuery();
  const notificationID = query.get('notificationID');
  const { offers, insurers } = useContext(UserDataContext);

  useEffect(() => {
    return () => markNotificationsAsRead([notificationID]);
  }, [notificationID]);

  if (!offers || !insurers) {
    return null;
  }

  const offer = offers.find(p => p.id === offerID);
  const insurer = insurers.find(i => i.id === offer.company);

  return <PolicyOrOfferDetails offer={offer} insurer={insurer} />;
}

export default Offer;
