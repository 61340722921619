import firebase from 'firebase/compat/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FlatButton from '../../components/FlatButton';

function DocumentSigned(props) {
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('document_signed', { platform: 'web' });
  }, []);

  return (
    <div className='px-8'>
      <h1 className='text-denim mb-8 leading-tight'>
        {t('Sie haben das Dokument unterschrieben. Dankeschön.')}
      </h1>
      <FlatButton secondary to='/dashboard' label={t('Zurück zum Dashboard')} />
    </div>
  );
}

export default DocumentSigned;
