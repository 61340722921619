import React, { useContext, useEffect, useState } from 'react';
import { Text } from 'grommet';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FlatButton from '../../components/FlatButton';
import classnames from 'classnames';
import { Field, Form } from 'react-final-form';
import { updateUser } from '../../api/user';
import { ProfileContext } from '../../contexts';
import { useHistory } from 'react-router-dom';

function CollectiveSignatureCheck() {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const { onboardingProgress } = profile || {};
  const history = useHistory();

  const [needsCollectiveSignature, toggleCollectiveSignature] = useState(
    undefined,
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    if (profile.needsCollectiveSignature) {
      toggleCollectiveSignature(profile.needsCollectiveSignature);
    }
  }, [profile]);

  const saveAndContinue = async (values = undefined) => {
    setError(null); // Remove previous error
    const nextStep = 'sign-mandate';
    const body = {
      onboardingProgress: nextStep,
      needsCollectiveSignature,
    };
    if (needsCollectiveSignature) {
      const { email, firstName, lastName, gender } = values;
      const collectiveSigner = { email, firstName, lastName, gender };
      const isEmailValid = yup
        .string()
        .email()
        .isValidSync(email);
      if (isEmailValid) {
        body.collectiveSigner = collectiveSigner;
      } else {
        return setError(t('Bitte gib eine gültige E-Mail-Adresse ein.'));
      }
    }
    try {
      await updateUser({
        ...body,
        modified: firebase.firestore.FieldValue.serverTimestamp(),
      });
      history.push(`/onboarding/${nextStep}`);
    } catch (error) {
      setError(error);
    }
  };

  const inputClasses =
    'mt-0 block w-full px-0 py-2 border-0 focus:ring-0 focus:border-denim text-denim placeholder-transparentDenim bg-flamingo p-4 font-serif font-extralight text-2xl';

  const fields = [
    {
      name: 'gender',
      placeholder: t('Anrede'),
      component: 'select',
      className:
        'bg-flamingo border-0 font-serif pl-0 text-2xl text-denim font-extralight',
      children: [
        <option label={t('Anrede')} value='' />,
        <option label={t('Frau')} value='female' />,
        <option label={t('Herr')} value='male' />,
      ],
      required: true,
    },
    {
      name: 'firstName',
      placeholder: t('Vorname'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'lastName',
      placeholder: t('Nachname'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'email',
      placeholder: t('E-Mail'),
      component: 'input',
      className: inputClasses,
      type: 'email',
      required: true,
    },
  ];

  return (
    <OnboardingLayout
      backgroundColor='bg-flamingo'
      onboardingProgress={onboardingProgress}
    >
      <div className='lg:w-1/2 lg:mr-32'>
        <h1 className='text-denim mb-8 leading-tight'>
          {t(
            'Unterschreibst du alleine oder seid ihr ein «Kollektiv zu zweit»?',
          )}
        </h1>
        <p className='text-denim mb-8'>
          {t(
            'Falls eine Kollektivunterschrift nötig ist, schicken wir dir das Mandat per E-Mail an die entsprechende Person.',
          )}
        </p>
        <div>
          {[true, false].map(value => {
            const isSelected = value === needsCollectiveSignature;
            return (
              <FlatButton
                secondary
                name={value.toString()}
                className={classnames(
                  isSelected ? 'bg-denim' : 'bg-flamingo',
                  isSelected ? 'text-white' : 'text-denim',
                  'my-4',
                )}
                label={t(
                  value
                    ? t('Wir unterschreiben zu zweit')
                    : t('Ich unterschreibe alleine'),
                )}
                onClick={() => toggleCollectiveSignature(value)}
              />
            );
          })}
        </div>
        {needsCollectiveSignature === false && (
          <FlatButton
            secondary
            label={t('Weiter')}
            onClick={saveAndContinue}
            type='submit'
            className='bg-denim text-white self-end'
            // isLoading={isLoading}
          />
        )}
      </div>
      {needsCollectiveSignature && (
        <div>
          <Form
            onSubmit={saveAndContinue}
            validate={() => {}}
            initialValues={profile?.collectiveSigner}
            render={({ handleSubmit, values }) => {
              const { email, firstName, lastName, gender } = values;
              return (
                <form onSubmit={handleSubmit}>
                  <div className='flex flex-col items-stretch lg:justify-between'>
                    <div className='flex flex-col lg:flex-row'>
                      <div className='flex-1 md:mr-8'>
                        <h1 className='text-denim mb-8 leading-tight'>
                          {t('Wer unterschreibt mit dir?')}
                        </h1>
                        {fields.map(field => (
                          <Field {...field} key={field.name} />
                        ))}
                      </div>
                    </div>
                    <FlatButton
                      secondary
                      label={t('Weiter')}
                      disabled={!email || !firstName || !lastName || !gender}
                      type='submit'
                      className='bg-denim text-white self-end my-8'
                      // isLoading={isLoading}
                    />
                  </div>
                </form>
              );
            }}
          />

          {error && <Text color='status-error'>{error}</Text>}
        </div>
      )}
    </OnboardingLayout>
  );
}

export default CollectiveSignatureCheck;
