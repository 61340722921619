import { Route, Switch, Redirect } from 'react-router-dom';
import 'moment/locale/de';
import OnboardingRoutes from '../pages/onboarding/OnboardingRoutes';
import DashboardRoutes from '../pages/dashboard/DashboardRoutes';
import FinishSignIn from '../pages/auth/FinishSignIn';
import firebase from 'firebase/compat/app';
import EnrollmentRoutes from './EnrollmentRoutes';
import GetApp from '../components/authentication/GetApp';
import AuthLayout from '../layouts/AuthLayout';
import DeepLinkRoute from './DeepLinkRoute';

const AppRoutes = ({ signedIn, didFinishOnboarding, isCompany }) => {
  const user = firebase.auth().currentUser;
  const hasEnrolledMultiFactor = user?.multiFactor?.enrolledFactors.length;

  return (
    <Switch>
      <Route path='/finish-sign-in/'>
        <FinishSignIn />
      </Route>
      {DeepLinkRoute}
      <Route path='/onboarding/'>
        {didFinishOnboarding ? (
          <Redirect to={{ pathname: '/dashboard' }} />
        ) : (
          <OnboardingRoutes />
        )}
      </Route>
      <Route path='/dashboard'>
        {// Only navigate to the dashboard if the user finished the onboarding process.
        didFinishOnboarding ? (
          <DashboardRoutes />
        ) : (
          <Redirect to={{ pathname: '/onboarding/personal-information' }} />
        )}
      </Route>
      <Route path='/enroll'>
        {hasEnrolledMultiFactor ? <Redirect to='/' /> : <EnrollmentRoutes />}
      </Route>
      <Route
        path='/get-app'
        component={() => (
          <AuthLayout>
            <GetApp />
          </AuthLayout>
        )}
      />
      <Route path='/'>
        <Redirect
          to={{
            pathname: signedIn
              ? didFinishOnboarding
                ? '/dashboard'
                : isCompany
                ? '/onboarding'
                : hasEnrolledMultiFactor
                ? '/get-app'
                : '/enroll'
              : '/start',
            state: { from: window.location.href },
          }}
        />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
