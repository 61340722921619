import firebase from 'firebase/compat/app';
import React, { useContext } from 'react';
import Intercom from 'react-intercom';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../contexts';

function CaveoIntercom(props) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);

  const { fullName, companyName, phoneNumber, email, gender, language } =
    profile || {};

  if (navigator.userAgent === 'ReactSnap') {
    return null;
  }

  return (
    <Intercom
      appID='v8ogwr3q'
      name={fullName || companyName || ''}
      user_id={firebase.auth().currentUser?.uid}
      phone={phoneNumber}
      email={email}
      gender={gender}
      language_override={language}
      {...props} // Take props from redux store plus additional props that are passed to this component.
      greeting={props.gender === 'male' ? t('Herr') : t('Frau')}
    />
  );
}

export default CaveoIntercom;
