import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

export default function AuthLayout({ children }) {
  return (
    <div className='container mx-auto max-w-2xl p-8 flex flex-col justify-center items-center min-h-screen'>
      <div>
        <Link to='/'>
          <img
            src={Logo}
            alt='Caveo'
            className='h-8 sm:h-10 lg:h-12 m-4 lg:m-12'
          />
        </Link>
      </div>
      <div className='flex items-center m-8'>
        <div className='bg-lightteal p-4 sm:p-8 rounded shadow-lg'>
          {children}
        </div>
      </div>
    </div>
  );
}
