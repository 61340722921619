import React from 'react';
import Fade from 'react-reveal/Fade';

export default function Grid({ items }) {
  return (
    <Fade bottom>
      <div className='flex flex-wrap md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        {items.map(i => i)}
      </div>
    </Fade>
  );
}
