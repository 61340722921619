import { SET_ENTITIES } from '../actions/actionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case SET_ENTITIES:
      if (action.entityType === 'offers') {
        return action.entities;
      }
      return state;
    default:
      return state;
  }
};

export default reducer;
