import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Grommet, ResponsiveContext } from 'grommet';
import { generate } from 'grommet/themes/base';
import { deepMerge } from 'grommet/utils';
import firebaseConfig from './firebaseConfig';
import caveoTheme from './theme';
import moment from 'moment';
import 'moment/locale/de';
import CaveoIntercom from './components/CaveoIntercom';
import AppRoutes from './routes/AppRoutes';
import { useTranslation } from 'react-i18next';
import { listenToUser } from './api/user';
import { listenToPolicies } from './api/policies';
import { listenToNotifications } from './api/notifications';
import { listenToOffers } from './api/offers';
import { listenToInsurers } from './api/insurers';
import { listenToCategories } from './api/categories';
import { setLocale } from 'yup';
import yupLocaleDe from 'yup-locale-de';
import CaveoLoader from './components/CaveoLoader';
import { ProfileContext, UserDataContext } from './contexts';
import PublicRoutes from './routes/PublicRoutes';
import { Helmet } from 'react-helmet';
import ShareBanner from './assets/share-banner.jpg';
import { listenToDocuments } from './api/documents';
import { listenToUserUploads } from './api/userUploads';
import { listenToReferrals } from './api/referrals';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Toaster } from 'react-hot-toast';

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true });

const analytics = firebase.analytics();

// Create a custom history to log analytics:
const history = createBrowserHistory();

// Log the location on every navigation event: // Makes weird events which are not useful
// history.listen(location => analytics.logEvent(location.pathname));

const baseSize = 20;
const scale = 4.75;

const theme = deepMerge(generate(baseSize, scale), caveoTheme);

function App(props) {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [policies, setPolicies] = useState(null);
  const [referrals, setReferrals] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [offers, setOffers] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [userUploads, setUserUploads] = useState(null);
  const [insurers, setInsurers] = useState(null);
  const [categories, setCategories] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const { i18n } = useTranslation();

  const isAuthenticated = !!user;
  const didFinishOnboarding = profile?.didFinishOnboarding;
  // const onboardingProgress = profile?.onboardingProgress;
  const databaseLanguage = profile?.language;
  const currentLanguage = i18n.language;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), authUser => {
      if (authUser) {
        setUser(authUser);
        analytics.logEvent('login', { platform: 'web' });
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      let unsubscribe = {};
      unsubscribe.user = listenToUser(userProfile => setProfile(userProfile));
      unsubscribe.policies = listenToPolicies(data => setPolicies(data));
      unsubscribe.referrals = listenToReferrals(data => setReferrals(data));
      unsubscribe.notifications = listenToNotifications(data =>
        setNotifications(data),
      );
      unsubscribe.offers = listenToOffers(data => setOffers(data));
      unsubscribe.documents = listenToDocuments(data => setDocuments(data));
      unsubscribe.userUploads = listenToUserUploads(data =>
        setUserUploads(data),
      );

      unsubscribe.insurers = listenToInsurers(data => setInsurers(data)); // Possible optimization: Fetch only the insurers the user needs for his policies and offers
      unsubscribe.categories = listenToCategories(data => setCategories(data));
      return () => {
        Object.values(unsubscribe).forEach(unsubscribeListener => {
          unsubscribeListener();
        });
      };
    }
  }, [isAuthenticated]);

  /**
   * Use the language from the database
   */
  useEffect(() => {
    if (databaseLanguage && databaseLanguage !== currentLanguage) {
      i18n.changeLanguage(databaseLanguage);
      setLocale(databaseLanguage.includes('de') ? yupLocaleDe : {});
      document.getElementById('html').setAttribute('lang', databaseLanguage);
      moment.locale(databaseLanguage);
    }
  }, [databaseLanguage, currentLanguage, i18n]);

  /**
   * If the user landed on the site via a campaign, save the ID for the registration later.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const campaign =
      searchParams.get('campaign') || searchParams.get('utm_campaign');
    const invitedBy = searchParams.get('invitedBy');
    const source = searchParams.get('source');
    // Redirect old campaign links to new Dynamic Links. Old campaign IDs can include uppercase letters and spaces. New campaign IDs are lowercase and dashed.
    if (campaign === 'Autohaus Schiess') {
      window.location.href = 'https://links.caveo.ch/hsDNc92DveRHEX4q8';
      return;
    }
    if (campaign === 'ExpatSavvy') {
      window.location.href = 'https://links.caveo.ch/YJP7L8mEwQjPaERbA';
      return;
    }
    if (campaign === 'KESD') {
      window.location.href = 'https://links.caveo.ch/s5diPMeW73cgmbFM7';
      return;
    }
    if (campaign === 'Optimatis Vorsorge-Lead') {
      window.location.href = 'https://links.caveo.ch/DA31197SFTUqvspg8';
      return;
    }
    // Save the campaign to use when the user registers:
    if (campaign) {
      window.localStorage.setItem('campaign', campaign);
    }
    if (invitedBy) {
      window.localStorage.setItem('invitedBy', invitedBy);
    }
    if (source) {
      window.localStorage.setItem('source', source);
    }
  }, []);

  if (isLoading || (isAuthenticated && !profile)) {
    return <CaveoLoader />;
  }

  // const isOnboardingLocation = window.location.pathname.includes('/onboarding');

  return (
    <Grommet
      id='grommet'
      style={{ height: '100%', width: '100%' }}
      theme={theme}
      themeMode='light'
    >
      <Helmet>
        <title>Caveo - Dein digitaler Versicherungstreuhänder</title>
        <meta
          name='title'
          content='Caveo - Dein digitaler Versicherungstreuhänder.'
        ></meta>
        <meta
          name='description'
          content='Die App für mehr Durchblick im Vorsorge- und Versicherungsdschungel.
1. App herunterladen | 2. Personalien erfassen | 3. Brokermandat unterschreiben | 4. Tschüss Versicherungsordner'
        ></meta>
        <meta property='og:type' content='website'></meta>
        <meta property='og:url' content='https://www.caveo.ch/'></meta>
        <meta
          property='og:title'
          content='Caveo - Dein digitaler Versicherungstreuhänder.'
        ></meta>
        <meta
          property='og:description'
          content='Die App für mehr Durchblick im Vorsorge- und Versicherungsdschungel.
1. App herunterladen | 2. Personalien erfassen | 3. Brokermandat unterschreiben | 4. Tschüss Versicherungsordner'
        ></meta>
        <meta property='og:image' content={ShareBanner}></meta>
        <meta property='twitter:card' content='summary_large_image'></meta>
        <meta property='twitter:url' content='https://www.caveo.ch/'></meta>
        <meta
          property='twitter:title'
          content='Caveo - Dein digitaler Versicherungstreuhänder.'
        ></meta>
        <meta
          property='twitter:description'
          content='Die App für mehr Durchblick im Vorsorge- und Versicherungsdschungel.
1. App herunterladen | 2. Personalien erfassen | 3. Brokermandat unterschreiben | 4. Tschüss Versicherungsordner'
        ></meta>
        <meta property='twitter:image' content={ShareBanner}></meta>
        <link rel='apple-touch-icon' href='%PUBLIC_URL%/logo200.png' />
      </Helmet>
      <Toaster position='top-center' />
      <ProfileContext.Provider value={profile}>
        <UserDataContext.Provider
          value={{
            policies,
            offers,
            notifications,
            insurers,
            documents,
            userUploads,
            referrals,
            categories,
          }}
        >
          <ResponsiveContext.Consumer>
            {size => {
              // const shouldRenderDashboardLayout =
              //   size === 'small' && isAuthenticated && !isOnboardingLocation;
              return (
                <React.Fragment>
                  {navigator.userAgent !== 'ReactSnap' && (
                    <ResponsiveContext.Consumer>
                      {size => (
                        <CaveoIntercom
                          hide_default_launcher={
                            size === 'small' && isAuthenticated
                          }
                        />
                      )}
                    </ResponsiveContext.Consumer>
                  )}
                  <Router history={history}>
                    {isAuthenticated ? (
                      <AppRoutes
                        signedIn={isAuthenticated}
                        didFinishOnboarding={didFinishOnboarding}
                        isCompany={profile?.isCompany}
                      />
                    ) : (
                      <PublicRoutes />
                    )}
                  </Router>
                </React.Fragment>
              );
            }}
          </ResponsiveContext.Consumer>
        </UserDataContext.Provider>
      </ProfileContext.Provider>
    </Grommet>
  );
}

export default App;
