import React from 'react';
import { useTranslation } from 'react-i18next';

function GenderSelect(props) {
  const { t } = useTranslation();
  const genderOptions = [
    {
      value: 'female',
      label: t('Frau'),
    },
    {
      value: 'male',
      label: t('Herr'),
    },
  ];
  return (
    <select {...props}>
      {genderOptions.map(({ label, value }) => (
        <option label={label} value={value} key={label} />
      ))}
    </select>
  );
}

export default GenderSelect;
