import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

function CookieFooter() {
  const { t } = useTranslation();
  const [hideFooter, toggleFooter] = useState(
    JSON.parse(localStorage.getItem('hideCookieFooter')),
  );
  const hide = () => {
    localStorage.setItem('hideCookieFooter', 'true');
    toggleFooter(true);
  };
  if (hideFooter) {
    return null;
  }
  return (
    <div className='flex flex-col sm:flex-row fixed justify-center sm:items-center p-4 bottom-0 left-0 right-0 w-full bg-white shadow-reverse-md'>
      <p className='text-xs sm:text-base mb-2 mr-0 sm:mb-0 sm:mr-4'>
        {t(
          'Durch die weitere Nutzung dieser Website stimmen Sie der Verwendung von Cookies und der Datenschutzerklärung von Caveo zu. ',
        )}
      </p>
      <Button label={t('Schliessen')} onClick={hide} />
    </div>
  );
}

export default CookieFooter;
