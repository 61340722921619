import React, { useEffect, useState } from 'react';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import CaveoLoader from './CaveoLoader';
import DashboardContainer from '../pages/dashboard/DashboardContainer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDF({ url, isLoading }) {
  const { t } = useTranslation();

  const [numPages, setNumPages] = useState(0);
  const [divWidth, setDivWidth] = useState(
    document.getElementById('pdf')?.offsetWidth || 0,
  );

  useEffect(() => {
    const handleResize = () =>
      setDivWidth(document.getElementById('pdf').offsetWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!url || isLoading) {
    return <CaveoLoader />;
  }

  return (
    <div id='pdf'>
      <a href={url} target='_blank' rel='noreferrer'>
        <DashboardContainer className='overflow-auto'>
          <Document
            options={{
              cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
            noData={t('PDF wird gesucht...')}
            onLoadSuccess={document => setNumPages(document.numPages)}
            file={url}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                width={divWidth || document.getElementById('pdf').offsetWidth}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </DashboardContainer>
      </a>
    </div>
  );
}

export default PDF;
