import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import { markNotificationsAsRead } from '../../api/notifications';
import PDF from '../../components/PDF';
import { UserDataContext } from '../../contexts';
import { useQuery } from '../../hooks';
import DocumentActions from './DocumentActions';
import { markDocumentAsSeen } from '../../api/documents';
import { useTranslation } from 'react-i18next';

function Document() {
  const { t } = useTranslation();
  const { documentID } = useParams();
  const query = useQuery();
  const notificationID = query.get('notificationID');
  const { documents } = useContext(UserDataContext);
  const document = documents && documents.find(p => p.id === documentID);
  const isPdf = document?.file?.title?.split('.').pop() === 'pdf';
  const isImg = ['jpg', 'png', 'jpeg'].includes(document?.file?.title?.split('.').pop());

  useEffect(() => {
    !document?.seenAt && markDocumentAsSeen(documentID);
    if (notificationID) {
      return () => markNotificationsAsRead([notificationID]);
    }
  }, [notificationID, documentID, document]);

  if (!documents) {
    return null;
  }

  return (
    <div className='flex flex-col xl:flex-row-reverse'>
      <div className='px-4 xl:w-1/2'>
        {isPdf && <PDF url={document?.signedDocument?.src || document?.file?.src} />}
        {isImg && <img className={"p-4"} alt={document?.file?.title} src={document?.file?.src} />}
      </div>
      <div className='p-4 xl:w-1/2'>
        <div className='mb-8'>
          <h1 className='text-4xl break-words h-full'>{document?.name}</h1>
          <p className='text-denim font-bold mt-4'>
            {t('Nachricht')}: {document?.message}
          </p>
        </div>
        <div className='flex space-x-4'>
        <Button
          href={document?.file?.src}
          target='_blank'
          label='Download'
          className={'my-4 hover:bg-teal'}
          backgroundColor='bg-teal'
        />
        </div>
        {document?.mustBeSigned && <DocumentActions document={document} />}
      </div>
    </div>
  );
}

export default Document;
