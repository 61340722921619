import {
  SET_ENTITIES,
  MARK_NOTIFICATIONS_AS_READ,
} from '../actions/actionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case SET_ENTITIES:
      if (action.entityType === 'notifications') {
        return action.entities;
      }
      return state;
    case MARK_NOTIFICATIONS_AS_READ:
      const idsToMarkAsRead = action.notifications.map(n => n.id);
      return state.map(notification => {
        if (idsToMarkAsRead.includes(notification.id)) {
          return {
            ...notification,
            isRead: true,
          };
        }
        return notification;
      });
    default:
      return state;
  }
};

export default reducer;
