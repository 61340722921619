import React from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { teal } from '../theme';
import classnames from 'classnames';
function SelectableCard({
  onClick,
  disabled,
  isSelected,
  children,
  className,
}) {
  return (
    <button
      className={classnames(
        'outline-none focus:outline-none h-full',
        disabled ? 'opacity-50 cursor-default' : 'opacity-100',
        className,
      )}
      style={{ outline: 'none' }}
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={classnames(
          'relative p-4 flex items-center justify-center h-full flex-col shadow-lg hover:shadow-xl rounded-xl',
          isSelected ? 'bg-lightteal' : 'bg-white',
        )}
      >
        {children}
        {isSelected && (
          <IoCheckmarkCircle
            color={teal}
            size={30}
            className='absolute right-2 bottom-2'
          />
        )}
      </div>
    </button>
  );
}

export default SelectableCard;
