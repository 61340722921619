import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SelectableCard from '../../components/SelectableCard';
import { UserDataContext } from '../../contexts';

function PolicyCard({ policy, onClick, isSelected, disabled }) {
  const { categories } = useContext(UserDataContext);
  const { t } = useTranslation();
  const history = useHistory();

  if (!categories || !policy.categories) return null;

  const categoryShortName = categories.find(c => c.id === policy.categories[0])
    ?.shortName;

  return (
    <SelectableCard
      disabled={disabled}
      onClick={
        onClick
          ? () => onClick(policy.id)
          : () => history.push(`/dashboard/policies/${policy.id}`)
      }
      isSelected={isSelected}
    >
      <img
        src={policy?.insurer?.logo?.src}
        alt=''
        className='w-full object-contain'
      />
      <h4 className='text-center'>
        {policy.categories
          ? t(`insuranceTypes::short::${categoryShortName}`)
          : t('Ihre Police wird angefordert.')}
      </h4>
      <p className='text-teal font-semibold text-center'>
        {policy.description || ''}
      </p>
    </SelectableCard>
  );
}

export default PolicyCard;
