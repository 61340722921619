import React from 'react';
import { Button } from 'grommet';
import ScaleLoader from 'react-spinners/ScaleLoader';

function AsyncButton(props) {
  return (
    <Button
      {...props}
      icon={
        props.isLoading ? <ScaleLoader height='20' color='white' /> : props.icon
      }
      disabled={props.disabled || props.isLoading}
    />
  );
}

export default AsyncButton;
