import firebase from 'firebase/compat/app';
import React from 'react';
import classnames from 'classnames';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import { IoArrowBack, IoClose } from 'react-icons/io5';
import { denim } from '../theme';
import { useHistory } from 'react-router-dom';

function OnboardingLayout({ backgroundColor, children, isWalkthroughView }) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={classnames(backgroundColor, 'min-h-full')}>
      {!isWalkthroughView && (
        <div className='container flex flex-row justify-between mx-auto my-auto p-8'>
          <Button
            plain
            color='text-denim'
            label={t('Zurück')}
            icon={<IoArrowBack color={denim} fontSize={20} />}
            onClick={() => history.goBack()}
          />
          <Button
            plain
            color='text-denim'
            label={t('Schliessen')}
            icon={<IoClose color={denim} fontSize={20} />}
            onClick={() => firebase.auth().signOut()}
          />
        </div>
      )}
      <div
        className={classnames(
          'container mx-auto my-auto flex flex-col xl:flex-row p-8 items-start justify-start overflow-auto h-full',
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default OnboardingLayout;
