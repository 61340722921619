import React from 'react';
import { Anchor } from 'grommet';
import { MdSend } from 'react-icons/md';
import IconButton from './IconButton';
import { useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';

function CombinedTextFileField({
  onFileChange,
  onTextChange,
  uploadLabel,
  name,
  placeholder,
  hideSendButton,
  onSend,
  file,
  text,
}) {
  const { t } = useTranslation('components');

  const handleFileChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    onFileChange(file);
  };

  const getLabel = () => {
    if (file) {
      return file.name;
    }
    if (uploadLabel) {
      return uploadLabel;
    }
    return t('Anhang wählen');
  };

  return (
    <div className='rounded-md  border-teal border mb-4 w-full'>
      <div className='h-48'>
        <textarea
          className='bg-superlightteal w-full h-full p-4 resize-none rounded-md placeholder-teal border-0'
          placeholder={placeholder}
          value={text}
          onChange={onTextChange}
        />
      </div>
      <div className='flex flex-row justify-between p-2 bg-superlightteal'>
        <div flex='shrink'>
          <label htmlFor={name || 'upload'}>
            <Anchor
              plain
              size='small'
              color='neutral-1'
              label={getLabel()}
              icon={<BsUpload size='24px' />}
              style={{
                fontWeight: 'normal',
              }}
            />
            <input
              type='file'
              id={name || 'upload'}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </label>
        </div>
        {!hideSendButton && (
          <IconButton
            onClick={onSend}
            icon={<MdSend color='white' />}
            disabled={!text}
          />
        )}
      </div>
    </div>
  );
}

export default CombinedTextFileField;
