import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import MultiFactorAuth from '../components/authentication/MultiFactorAuth';
import VerifyEmail from '../components/authentication/VerifyEmail';
import AuthLayout from '../layouts/AuthLayout';

export default function EnrollmentRoutes() {
  const match = useRouteMatch();
  const user = firebase.auth().currentUser;
  const history = useHistory();

  return (
    <Switch>
      <Route
        path={`${match.url}/password`}
        component={() => (
          <AuthLayout>
            <MultiFactorAuth history={history} />
          </AuthLayout>
        )}
        element
      />
      <Route
        path={`${match.url}/phone`}
        component={() => (
          <AuthLayout>
            <MultiFactorAuth history={history} />
          </AuthLayout>
        )}
      />
      <Route
        path={`${match.url}/verify-email`}
        component={() => <VerifyEmail history={history} />}
      />
      <Route path={match.url}>
        <Redirect
          to={
            !user.providerData
              .map(({ providerId }) => providerId)
              .includes('password')
              ? '/enroll/password'
              : !user.emailVerified
              ? '/enroll/verify-email'
              : '/enroll/phone'
          }
        />
      </Route>
    </Switch>
  );
}
