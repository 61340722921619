import React, { forwardRef, useEffect, useState } from 'react';
import { ResponsiveContext } from 'grommet';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import FlatButton from '../FlatButton';

const SignaturePad = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [canvasSize, setCanvasSize] = useState({ height: 0, width: 0 });

  useEffect(() => {
    const handleOrientationChange = e => {
      // setState(state => ({
      //   screenOrientationAngle: getOrientationAngle(),
      //   screenRotated: !state.screenRotated,
      //   screenWidth: state.screenHeight,
      //   screenHeight: state.screenWidth,
      // }));
    };
    window.addEventListener('orientationchange', handleOrientationChange);
    return () =>
      window.removeEventListener('orientationchange', handleOrientationChange);
  }, []);

  // const getOrientationAngle = () =>
  //   window.screen.orientation
  //     ? window.screen.orientation.angle // Safari doesn't implement window.screen.orientation
  //     : window.orientation || 0;

  const canvasContainer = document.getElementById('canvasContainer');

  useEffect(() => {
    setCanvasSize({
      height: canvasContainer?.offsetHeight || 200,
      width: canvasContainer?.offsetWidth || 200,
    });
  }, [canvasContainer]);

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <div>
            <div
              id='canvasContainer'
              className={`border rounded-xl ${props.className}`}
            >
              <SignatureCanvas canvasProps={canvasSize} ref={ref} />
            </div>
            <div className='my-4'>
              <FlatButton
                secondary
                onClick={() => ref.current.clear()}
                label={t('Unterschrift löschen')}
              />
            </div>
          </div>
        );
      }}
    </ResponsiveContext.Consumer>
  );
});

export default SignaturePad;
