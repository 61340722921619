import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'moment/locale/de';
import FinishSignIn from '../pages/auth/FinishSignIn';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../pages/auth/SignIn';
import CookieFooter from '../components/CookieFooter';
import DeepLinkRoute from './DeepLinkRoute';

const PublicRoutes = () => {
  return (
    <Fragment>
      <ScrollToTop elementID='grommet' />
      <Switch>
        <Route path='/finish-sign-in/'>
          <FinishSignIn />
        </Route>
        {DeepLinkRoute}
        <Route path='/'>
          <SignIn />
        </Route>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
      <CookieFooter />
    </Fragment>
  );
};

export default PublicRoutes;
