import React from 'react';
import { connect } from 'react-redux';
import { Box, Text, Button, Heading, TextInput, RadioButton } from 'grommet';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { Translation } from 'react-i18next';
import { setUser } from '../../store/actions';
import { logError } from '../../utils/logging';

const mapStateToProps = state => ({
  userID: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
  setUser: data => dispatch(setUser(data)),
});

class PensionPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPensionPlan: false,
      pensionPlanInstitute: '',
    };
  }

  setCompanies = company => {
    let newCompanies;
    const { selectedCompanies } = this.state;
    if (selectedCompanies.includes(company.id)) {
      newCompanies = selectedCompanies.filter(c => c !== company.id);
    } else {
      newCompanies = [...selectedCompanies, company.id];
    }
    this.setState({ selectedCompanies: newCompanies });
  };

  saveAndContinue = () => {
    const db = firebase.firestore();
    const body = {
      onboardingProgress: 'sign-mandate',
      hasPensionPlan: this.state.hasPensionPlan,
      pensionPlanInstitute: this.state.pensionPlanInstitute,
    };
    return db
      .collection('users')
      .doc(this.props.userID)
      .set(
        {
          ...body,
          modified: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true },
      )
      .then(
        response => {
          this.props.setUser(body);
          this.props.history.push('/onboarding/sign-mandate');
        },
        error => logError(error),
      );
  };

  render() {
    return (
      <Translation>
        {t => (
          <OnboardingLayout
            steps={[
              { icon: 'MdLockOpen', fulfilled: true },
              { icon: 'MdDashboard', fulfilled: false },
              { icon: 'FaPen', fulfilled: false },
              { icon: 'MdVerifiedUser', fulfilled: false },
            ]}
            step='pension-plan'
            onboardingProgress={this.props.onboardingProgress}
          >
            <Box flex={false} pad='large' align='center'>
              <Heading textAlign='center' level='1' color='neutral-1'>
                {t('onboarding//PensionPlan::heading')}
              </Heading>
              <Text textAlign='center'>
                {t('onboarding//PensionPlan::lead')}
              </Text>
              <Box>
                <Box direction='row' justify='center' pad='large'>
                  <Box pad='small'>
                    <RadioButton
                      checked={!this.state.hasPensionPlan}
                      label={t('common//no')}
                      name='Nein'
                      onChange={() => this.setState({ hasPensionPlan: false })}
                    />
                  </Box>
                  <Box pad='small'>
                    <RadioButton
                      checked={this.state.hasPensionPlan}
                      label={t('common//yes')}
                      name='Ja'
                      onChange={() => this.setState({ hasPensionPlan: true })}
                    />
                  </Box>
                </Box>
              </Box>
              {this.state.hasPensionPlan && (
                <Box gap='medium'>
                  <Text>{t('onboarding//PensionPlan::institute')}</Text>
                  <TextInput
                    onChange={({ target: { value } }) =>
                      this.setState({ pensionPlanInstitute: value })
                    }
                  />
                </Box>
              )}
            </Box>
            <Button
              primary
              label={t('common//next')}
              onClick={this.saveAndContinue}
            />
          </OnboardingLayout>
        )}
      </Translation>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PensionPlan);
