export const arctic = '#88fafa';
export const sea = '#367489';
export const teal = '#8cc0bd';
export const denim = '#102645';
export const ocean = '#275665';
export const raspberry = '#e52d53';
export const ice = '#c8d1d3';
export const smoke = '#eaedf0';
export const asphalt = '#657388';
export const lemon = '#F7F273';
export const flamingo = '#F3B1BE';

export const petrolGradient = 'linear-gradient(#367489, #275665)';

const colors = {
  'accent-1': raspberry,
  'accent-2': lemon,
  'accent-3': flamingo,
  'neutral-1': sea,
  'neutral-2': teal,
  'neutral-3': denim,
  'neutral-4': ocean,
  brand: arctic,
  focus: arctic,
  'light-1': ice,
  'light-2': smoke,
  'dark-1': asphalt,
  'dark-3': sea,
  text: {
    dark: ice,
    light: denim,
  },
  border: {
    dark: sea,
    light: sea,
  },
  control: {
    dark: 'brand',
    light: 'brand',
  },
};

const theme = {
  global: {
    colors,
    font: {
      family: 'Open Sans',
      weight: 400,
      height: 1.5,
    },
    focus: {
      border: {
        color: 'none',
      },
    },
    breakpoints: {
      small: {
        value: 1000,
      },
    },
  },
  anchor: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: {
      dark: 'accent-1',
      light: 'neutral-1',
    },
  },
  box: {
    focusIndicator: false,
  },
  button: {
    border: {
      width: 0,
      radius: '0px',
    },
    padding: {
      vertical: '15px',
    },
    extend: props => {
      if (props.primary) {
        return {
          backgroundColor: props.colorValue || arctic,
          borderColor: arctic,
          color: props.textColor || denim,
          fontWeight: 'bold',
        };
      }
      if (props.plain) {
        return {
          fontWeight: 'bold',
        };
      }
      return {
        backgroundColor: denim,
        borderColor: denim,
        color: arctic,
        fontWeight: 'bold',
      };
    },
  },
  heading: {
    font: {
      family: 'Open Sans',
    },
    extend: props => ({
      wordBreak: 'break-word',
      fontWeight: '200',
      lineHeight: 1.5,
    }),
  },
  textInput: {
    extend: {
      fontWeight: 200,
    },
  },
  accordion: {
    icons: {
      color: ocean,
    },
    border: {
      color: 'transparent',
    },
  },
  checkBox: {
    border: {
      color: {
        dark: 'neutral-1',
        light: 'neutral-1',
      },
    },
    color: { dark: 'neutral-1', light: 'neutral-1' },
    hover: {
      border: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
    },
    toggle: {
      color: {
        dark: '#d9d9d9',
        light: '#d9d9d9',
      },
    },
  },
  radioButton: {
    border: {
      color: {
        dark: 'neutral-1',
        light: 'neutral-1',
      },
    },
    check: {
      color: {
        dark: 'neutral-1',
        light: 'neutral-1',
      },
    },
    color: { dark: 'neutral-1', light: 'neutral-1' },
    hover: {
      border: {
        color: {
          dark: 'neutral-1',
          light: 'neutral-1',
        },
      },
    },
    toggle: {
      color: {
        dark: '#d9d9d9',
        light: '#d9d9d9',
      },
    },
  },
  textArea: {
    extend: {
      fontWeight: 'normal',
    },
  },
  menu: {
    background: 'neutral-1',
  },
  paragraph: {
    extend: props => ({
      lineHeight: props.size && props.size === 'large' ? 1.75 : 1.5,
    }),
  },
};

export default theme;
