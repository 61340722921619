import React from 'react';
import { Button } from 'grommet';

function IconButton({ size, background, ...rest }) {
  return (
    <div className='rounded-full bg-teal'>
      <Button {...rest} />
    </div>
  );
}

export default IconButton;
