import React from 'react';
import loadImage from 'blueimp-load-image';
import { Button } from 'grommet';

function ExifImageUploader(props) {
  const handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    // See: https://nsulistiyawan.github.io/2016/07/11/Fix-image-orientation-with-Javascript.html#Include-that-library-in-your-html-head
    loadImage.parseMetaData(file, data => {
      var orientation = 0;
      if (data.exif) {
        orientation = data.exif.get('Orientation');
      }
      loadImage(
        file,
        canvas => {
          const base64data = canvas.toDataURL('image/jpeg');

          props.onChange({
            file: file,
            src: base64data,
          });
        },
        {
          // should be set to canvas : true to activate auto fix orientation
          canvas: true,
          orientation: orientation,
          maxWidth: props.maxWidth || 1000,
          maxHeight: props.maxHeight || 1000,
        },
      );
    });
  };

  return (
    <label htmlFor='upload'>
      <Button as='a' label={props.label} />
      <input
        type='file'
        accept='image/*'
        id='upload'
        style={{ display: 'none' }}
        {...props}
        onChange={handleImageChange}
      />
    </label>
  );
}

export default ExifImageUploader;
