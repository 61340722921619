import firebase from 'firebase/compat/app';
import { convertFirestoreTimestamps } from 'firebase-helpers';
import { logError } from '../utils/logging';

export function listenToUser(onSnapshot) {
  const db = firebase.firestore();
  return db
    .doc(`users/${firebase.auth().currentUser.uid}`)
    .onSnapshot(snapshot => {
      if (snapshot.exists) {
        const data = convertFirestoreTimestamps(snapshot.data());
        onSnapshot(data);
      } else {
        onSnapshot({});
      }
    }, logError);
}

export function updateUser(data) {
  const db = firebase.firestore();
  const userID = firebase.auth().currentUser.uid;
  return db.doc(`users/${userID}`).set(data, { merge: true });
}
