import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PDF from '../../components/PDF';
import { ProfileContext } from '../../contexts';

function Mandate(props) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const { mandate, vag } = profile;

  return (
    <div>
      <h1 className='text-denim'>{t('Mein Maklermandat')}</h1>
      <PDF url={mandate.src} />
      <PDF url={vag.src} />
    </div>
  );
}

export default Mandate;
