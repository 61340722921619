import React from 'react';
import PlayStore from '../assets/play-store.png';
import AppStore from '../assets/app-store.png';

function StoreBadges(props) {
  return (
    <div className='flex flex-row mt-8'>
      <a href='https://apps.apple.com/us/app/caveo/id1548480677'>
        <img src={AppStore} alt='App Store' className='h-10 mr-8' />
      </a>
      <a href='https://play.google.com/store/apps/details?id=ch.caveo.android'>
        <img src={PlayStore} alt='Play Store' className='h-10' />
      </a>
    </div>
  );
}

export default StoreBadges;
