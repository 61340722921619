// PhoneAuth
export const TRY_AUTH = 'TRY_AUTH';

// user
export const TOGGLE_USER_STATUS = 'TOGGLE_USER_STATUS';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_USER = 'SET_USER';

// Entities
export const SET_ENTITIES = 'SET_ENTITIES';
export const IS_FETCHING_ENTITIES = 'IS_FETCHING_ENTITIES';

// UI
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// Notifications
export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ';
