import React from 'react';
import { useQuery } from '../../hooks';
import { useHistory } from 'react-router-dom';
import EmailAuth from '../../components/authentication/EmailAuth';
import MultiFactorAuth from '../../components/authentication/MultiFactorAuth';
import AuthLayout from '../../layouts/AuthLayout';

function SignIn() {
  const query = useQuery();
  const history = useHistory();
  const type = query.get('type') || 'personal';
  const isBusiness = type === 'business';

  return (
    <AuthLayout>
      {isBusiness ? (
        <EmailAuth history={history} />
      ) : (
        <MultiFactorAuth history={history} />
      )}
    </AuthLayout>
  );
}

export default SignIn;
