import { getAuth } from 'firebase/auth';
import StackdriverErrorReporter from 'stackdriver-errors-js';

const isProduction = process.env.NODE_ENV === 'production';

const STACKDRIVER_CONFIG = {
  key: 'AIzaSyB_uhw0n1RSSBlMUaAqfTsmWRDh84oWfzc',
  projectId: 'caveo-5ed92',
  version: process.env.REACT_APP_VERSION,
  disabled: !isProduction,
  service: 'web',
};

const errorHandler = new StackdriverErrorReporter();

errorHandler.start(STACKDRIVER_CONFIG);

export const logError = e => {
  if (!isProduction) {
    console.error(e);
  } else {
    const user = getAuth().currentUser;
    if (user) {
      errorHandler.setUser(user.uid);
    }
    errorHandler.report(e);
  }
};
