import React from 'react';
import { useTranslation } from 'react-i18next';
import FlatButton from '../../components/FlatButton';

function ClaimSent(props) {
  const { t } = useTranslation();
  return (
    <div className='px-8'>
      <h1 className='text-denim mb-8 leading-tight'>
        {t('Alles kommt gut. Deine Schadenmeldung wurde übermittelt.')}
      </h1>
      <h1 className='text-denim mb-8 leading-tight'>
        {t('Die Versicherungsgesellschaft wird sich in Kürze bei dir melden.')}
      </h1>
      <FlatButton secondary to='/dashboard' label={t('Zurück zum Dashboard')} />
    </div>
  );
}

export default ClaimSent;
