import React, { useContext, useState } from 'react';
import firebase from 'firebase/compat/app';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { ProfileContext } from '../../contexts';
import { updateUser } from '../../api/user';
import { useHistory } from 'react-router-dom';

function MandateAnnouncer(props) {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const history = useHistory();

  const { onboardingProgress, isCompany, firstName, contactFirstName } =
    profile || {};

  const [isLoading, toggleLoading] = useState(false);

  const saveAndContinue = async () => {
    toggleLoading(true);
    const nextStep = isCompany ? 'mandate-summary' : 'pension-plan';
    const body = {
      onboardingProgress: nextStep,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await updateUser(body);
    history.push(`/onboarding/${nextStep}`);
  };

  return (
    <OnboardingLayout
      backgroundColor='bg-teal'
      onboardingProgress={onboardingProgress}
    >
      <div className='lg:mr-8 lg:w-1/3'>
        <h1 className='text-denim text-2xl'>
          {t('almostDone', { name: isCompany ? contactFirstName : firstName })}
        </h1>
        <p className='text-denim mt-8'>
          {t(
            'Um bei deinen Versicherungen die Policen einzuholen, benötigen wir deine Bestätigung. Mit deiner Unterschrift bestätigst du, dass wir dich betreuen können.',
          )}
        </p>
        <p className='text-denim mt-8'>
          {t(
            'Dies nennt sich Maklermandat. Falls deine Pläne sich ändern sollten, kannst du dieses jederzeit wieder künden. Mehr dazu auf der nächsten Seite.',
          )}
        </p>
        <Button
          primary
          className='lg:self-end my-8'
          backgroundColor='bg-denim'
          label={t('Finde ich super!')}
          isLoading={isLoading}
          onClick={saveAndContinue}
        />
      </div>
    </OnboardingLayout>
  );
}

export default MandateAnnouncer;
