import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
// German:
import de from './translations/de/de.json';
import commonDE from './translations/de/common.json';
import onboardingDE from './translations/de/onboarding.json';
import componentsDE from './translations/de/components.json';
import insuranceTypesDE from './translations/de/insuranceTypes.json';
import referralDE from './translations/de/referral.json';
// English:
import translationsEN from './translations/en/translation.json';
import commonEN from './translations/en/common.json';
import onboardingEN from './translations/en/onboarding.json';
import componentsEN from './translations/en/components.json';
import insuranceTypesEN from './translations/en/insuranceTypes.json';
import referralEN from './translations/en/referral.json';

const resources = {
  en: {
    translation: {
      ...translationsEN,
      insuranceTypes: insuranceTypesEN,
    },
    common: commonEN,
    onboarding: onboardingEN,
    components: componentsEN,
    referral: referralEN
  },
  de: {
    translation: {
      ...de,
      insuranceTypes: insuranceTypesDE,
    },
    common: commonDE,
    onboarding: onboardingDE,
    components: componentsDE,
    referral: referralDE
  },
};

export const shortLanguage =
  navigator.language.slice(0, 2) || navigator.browserLanguage.slice(0, 2);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: shortLanguage,
    fallbackLng: 'de',
    ns: Object.keys(resources.en),
    nsSeparator: '//', // If true, colons in strings are not shown
    keySeparator: '::', // We use double colons to nest translations otherwise dots in strings are interpreted as key separators.
    interpolation: {
      escapeValue: false, // react already saves from xss
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

export default i18n;
