import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import firebase from 'firebase/compat/app';
import FlatButton from '../../components/FlatButton';
import CaveoLoader from '../../components/CaveoLoader';
import SignaturePad from '../../components/SignaturePad/SignaturePad';
import { useHistory } from 'react-router-dom';

const StatusText = ({ children }) => (
  <div className='py-2 px-4 border border-denim rounded-md my-4 inline-block'>
    <p className='text-lg font-semibold text-denim'>{children}</p>
  </div>
);

function OfferActions({ offer }) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const signaturePad = useRef(null);
  const history = useHistory();

  const { id, status, validUntil } = offer;

  const declineOffer = async () => {
    try {
      setLoading(true);
      await firebase
        .firestore()
        .collection('offers')
        .doc(id)
        .update({
          status: 'declined',
          modified: new Date(),
        });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const acceptOffer = async () => {
    if (signaturePad.current.isEmpty()) {
      return alert(t('Bitte unterschreibe im grünen Feld.'));
    }
    setLoading(true);
    const base64PNG = signaturePad.current.getTrimmedCanvas().toDataURL();
    const userID = firebase.auth().currentUser.uid;
    try {
      const reference = firebase.storage().ref(`signatures/offers/${id}.png`);
      await reference.putString(base64PNG, 'data_url');
      // Sign mandate, save to database and continue:
      const downloadUrl = await reference.getDownloadURL();
      const signaturePath = reference.fullPath;
      if (!downloadUrl || !signaturePath) {
        throw new Error('Could not get download URL or signature path.');
      }
      // Update offer in database:
      await firebase
        .firestore()
        .collection('offers')
        .doc(id)
        .update({
          status: 'accepted',
          signature: downloadUrl,
          modified: new Date(),
        });
      // Sign the PDF on the server:
      firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('signOffer')({
        userID,
        offerID: id,
      });
      history.push(`/dashboard/offers/${id}/signed`);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <CaveoLoader />;
  }

  if (status === 'declined') {
    return <StatusText>{t('Sie haben diese Offerte abgelehnt.')}</StatusText>;
  }

  if (status === 'accepted') {
    return <StatusText>{t('Sie haben diese Offerte akzeptiert.')}</StatusText>;
  }

  if (isAfter(new Date(), new Date(validUntil))) {
    return <StatusText>{t('Diese Offerte ist abgelaufen.')}</StatusText>;
  }

  return (
    <div className='mt-8'>
      <h4>{t('Offerte im Feld unten unterschreiben')}</h4>
      <SignaturePad ref={signaturePad} className='bg-lightteal border-teal' />
      <div className='flex'>
        <div className='mr-4'>
          <FlatButton
            secondary
            color='text-denim'
            backgroundColor='bg-white'
            label={t('Ablehnen')}
            onClick={declineOffer}
            isLoading={isLoading}
          />
        </div>
        <FlatButton
          primary
          color='text-white'
          backgroundColor='bg-arctic'
          label={t('Offerte einreichen')}
          onClick={acceptOffer}
          isLoading={isLoading}
        />
      </div>
      {error && <p className='text-red'>{error}</p>}
    </div>
  );
}

export default OfferActions;
