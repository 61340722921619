import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PolicyOrOfferDetails from '../../components/PolicyOrOfferDetails';
import { UserDataContext } from '../../contexts';

function Policy({ route }) {
  const { policyID } = useParams();
  const { policies, insurers } = useContext(UserDataContext);
  if (!policies || !insurers) {
    return null;
  }
  const policy = policies.find(p => p.id === policyID);
  const insurer = insurers.find(i => i.id === policy.company);
  return <PolicyOrOfferDetails policy={policy} insurer={insurer} />;
}

export default Policy;
