import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import PensionPlan from './PensionPlan';
import OnboardingFinished from './OnboardingFinished';
import SignMandate from './SignMandate';
import UploadIdentification from './UploadIdentification';
import ChooseCompanies from './ChooseCompanies';
import Referrer from './Referrer';
import firebase from 'firebase/compat/app';
import CollectiveSignatureCheck from './CollectiveSignatureCheck';
import CreateProfile from './CreateProfile';
import MandateAnnouncer from './MandateAnnouncer';
import MandateSummary from './MandateSummary';

function OnboardingRoutes(props) {
  const match = useRouteMatch();
  const user = firebase.auth().currentUser;

  if (!user) {
    return <Redirect to='/sign-in' />;
  }

  return (
    <Switch>
      <Route path={`${match.url}/finished/`} component={OnboardingFinished} />
      <Route path={`${match.url}/referrer/`} component={Referrer} />
      <Route
        path={`${match.url}/upload-identification/`}
        component={UploadIdentification}
      />
      <Route path={`${match.url}/sign-mandate/`} component={SignMandate} />
      <Route
        path={`${match.url}/collective-signature/`}
        component={CollectiveSignatureCheck}
      />
      <Route path={`${match.url}/pension-plan/`} component={PensionPlan} />
      <Route path={`${match.url}/mandate-summary/`}>
        <MandateSummary />
      </Route>
      <Route path={`${match.url}/mandate-announcer/`}>
        <MandateAnnouncer />
      </Route>
      <Route path={`${match.url}/choose-companies/`}>
        <ChooseCompanies />
      </Route>
      <Route path={`${match.url}/create-profile/`}>
        <CreateProfile />
      </Route>
      {
        // Keep this route after renaming the step from 'personal-information' to 'create-profile':
        <Route path={`${match.url}/personal-information/`}>
          <Redirect to='/onboarding/create-profile/' />
        </Route>
      }
      <Route path={match.url}>
        <Redirect to='/onboarding/create-profile/' />
      </Route>
    </Switch>
  );
}

export default OnboardingRoutes;
