import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { SET_ENTITIES, IS_FETCHING_ENTITIES } from './actionTypes';
import { Dispatch } from 'redux';
import { logError } from '../../utils/logging';

enum EntityType {
  notifications = 'notifications',
  policies = 'policies',
  offers = 'offers',
}

type Entities = Array<any>;

export const setEntities = (entityType: EntityType, entities: Entities) => ({
  type: SET_ENTITIES,
  entityType: entityType,
  entities,
});

export const isFetchingEntities = (data: any) => ({
  type: IS_FETCHING_ENTITIES,
  ...data,
});

export const fetchEntitiesForUser = (entityType: EntityType) => (
  dispatch: Dispatch,
  getState: any,
) => {
  const userID = getState().auth.token;
  const db = firebase.firestore();
  const entitiesRef = db.collection(entityType);
  return entitiesRef
    .where('user', '==', userID)
    .get()
    .then(async snapshot => {
      if (snapshot.empty) {
        return [];
      }
      return Promise.all(
        snapshot.docs.map(async doc => {
          const entity = doc.data();
          entity.id = doc.id;
          // Turn all Firebase dates into JS dates:
          Object.keys(entity).forEach((key: string) => {
            if (entity[key] && entity[key].toDate) {
              entity[key] = entity[key].toDate();
            }
          });
          if (entityType === 'notifications') {
            if (entity.offer) {
              entity['offer'] = await db
                .collection('offers')
                .doc(entity.offer)
                .get()
                .then((snap: any) => ({ ...snap.data(), id: snap.id }))
                .catch((e: Error) => logError(e));
            }
          }
          return Promise.resolve(entity);
        }),
      ).catch(e => logError(e));
    })
    .then(entities => {
      if (entities) {
        dispatch(setEntities(entityType, entities));
      }
    })
    .catch(err => {
      logError(`Error getting ${entityType}: ${err}`);
    });
};
