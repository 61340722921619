import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../contexts';
import { Form, Field } from 'react-final-form';
import Button from '../../components/Button';
import { updateUser } from '../../api/user';
import { useHistory } from 'react-router-dom';
import OnboardingLayout from '../../layouts/OnboardingLayout';

function CreateProfile() {
  const { t } = useTranslation();
  const profile = useContext(ProfileContext);
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { isCompany } = profile;

  const inputClasses =
    'mt-0 block w-full px-0 py-2 border-0 focus:ring-0 focus:border-denim text-denim placeholder-transparentDenim bg-arctic p-4 font-serif font-extralight text-xl';

  const addressFields = [
    {
      name: 'street',
      placeholder: t('Strasse'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'number',
      placeholder: t('Hausnummer'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'zipCode',
      placeholder: t('PLZ'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'city',
      placeholder: t('Ort'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
  ];

  const companyFields = [
    {
      name: 'companyName',
      placeholder: t('Firma'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'industry',
      placeholder: t('Branche'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    ...addressFields,
    {
      name: 'phoneNumber',
      placeholder: t('Telefonnummer'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
  ];

  const companyContactFields = [
    {
      name: 'contactGender',
      placeholder: t('Anrede'),
      component: 'select',
      className:
        'bg-arctic border-0 font-serif pl-0 text-xl text-denim font-extralight',
      children: [
        <option label={t('Anrede')} value='' />,
        <option label={t('Frau')} value='female' />,
        <option label={t('Herr')} value='male' />,
      ],
      required: true,
    },
    {
      name: 'contactFirstName',
      placeholder: t('Vorname'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'contactLastName',
      placeholder: t('Nachname'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'contactPhone',
      placeholder: t('Telefonnummer'),
      component: 'input',
      className: inputClasses,
      required: true,
    },
    {
      name: 'contactEmail',
      placeholder: t('E-Mail'),
      component: 'input',
      className: inputClasses,
      type: 'email',
      required: true,
    },
  ];

  const fields = [...companyFields, ...companyContactFields];

  const onSubmit = async values => {
    try {
      setLoading(true);
      await updateUser({ ...values, onboardingProgress: 'choose-companies' });
      setLoading(false);
      history.push('/onboarding/choose-companies');
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const validate = () => {};

  return (
    <OnboardingLayout backgroundColor='bg-arctic'>
      <div className='container mx-auto my-auto p-8 items-center'>
        {error && <p className='text-red'>{error}</p>}
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={profile}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col items-stretch lg:justify-between'>
                <div className='flex flex-col lg:flex-row'>
                  <div className='flex-1 md:mr-8'>
                    <h2 className='text-2xl text-denim lg:mb-4 my-4'>
                      {isCompany
                        ? t('Deine Firma')
                        : t('Persönliche Informationen')}
                    </h2>
                    {companyFields.map(field => (
                      <Field {...field} />
                    ))}
                  </div>
                  {companyFields.every(field => values[field.name]) && (
                    <div className='flex-1'>
                      <h2 className='text-denim text-2xl lg:mb-4 my-4'>
                        {t('Wer ist die Kontaktperson?')}
                      </h2>
                      {companyContactFields.map(field => (
                        <Field {...field} />
                      ))}
                    </div>
                  )}
                </div>
                <Button
                  isLoading={isLoading}
                  disabled={!fields.every(f => values[f.name])}
                  type='submit'
                  backgroundColor='bg-denim'
                  label={t('Weiter')}
                  className='lg:self-end my-8'
                />
              </div>
            </form>
          )}
        />
      </div>
    </OnboardingLayout>
  );
}

export default CreateProfile;
