import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import CaveoSwitch from './CaveoSwitch';
import { updateUser } from '../api/user';

function LanguageSwitcher(props) {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const toggleLanguage = useCallback(() => {
    const newLanguage = language.includes('de') ? 'en' : 'de';
    if (firebase.auth().currentUser) {
      updateUser({ language: newLanguage });
    } else {
      i18n.changeLanguage(newLanguage);
    }
  }, [language, i18n]);

  return (
    <CaveoSwitch
      id='language-toggler'
      labels={{
        left: 'English',
        right: 'Deutsch',
      }}
      isChecked={language.includes('de')}
      onChange={toggleLanguage}
    />
  );
}

export default LanguageSwitcher;
