import React from 'react';
import { Image, Box, Layer, Button } from 'grommet';
import DefaultAvatar from '../assets/default-avatar.jpg';
import { MdClose } from 'react-icons/md';

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLightbox: false,
    };
  }

  size = this.props.size || '40px';

  content = () => (
    <Box
      round={'full'}
      width={{ min: this.size, max: this.size }}
      height={{ min: this.size, max: this.size }}
      border={this.props.border}
      overflow='hidden'
      background={this.props.background || 'white'}
    >
      <Image
        src={this.props.image || DefaultAvatar}
        fit={this.props.fit || 'cover'}
      />
    </Box>
  );

  render() {
    return (
      <React.Fragment>
        {this.props.withLightbox ? (
          <Button
            disabled={!this.props.withLightbox}
            onClick={() => this.setState({ showLightbox: true })}
          >
            {this.content()}
          </Button>
        ) : (
          this.content()
        )}
        {this.props.withLightbox && this.state.showLightbox && (
          <Layer full animation='fadeIn'>
            <Box fill background='black'>
              <Box style={{ position: 'absolute' }}>
                <Button
                  margin='small'
                  onClick={() => this.setState({ showLightbox: false })}
                >
                  <MdClose size={30} />
                </Button>
              </Box>
              <Image src={this.props.image || DefaultAvatar} fit='contain' />
            </Box>
          </Layer>
        )}
      </React.Fragment>
    );
  }
}

export default Avatar;
