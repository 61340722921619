import React from 'react';
import { Form, Text } from 'grommet';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import InputButtonCombination from '../InputButtonCombination';
import {
  EmailAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getMultiFactorResolver,
  linkWithCredential,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';

export default function EmailAndPasswordAuth({
  setRecaptcha,
  startTimer,
  setState,
  state,
  errorStrings,
  history,
  setResolver,
}) {
  const { t } = useTranslation();

  const auth = firebase.auth();
  const user = auth?.currentUser;

  const { mode, isLoading, showPasswordInput, password, email, error } = state;

  const submitEmail = async () => {
    setState({ isLoading: true, error: null });
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length) {
        setState({ showPasswordInput: true, password: '', mode: 'logIn' });
      } else {
        const findUserByEmail = firebase
          .app()
          .functions('europe-west6')
          .httpsCallable('findUserByEmail');
        const { data } = await findUserByEmail({ email });
        if (data?.uid) {
          setState({ showPhoneInput: true, phone: '+41' });
        } else {
          setState({
            showPasswordInput: true,
            password: '',
            mode: 'signUp',
          });
        }
      }
    } catch (e) {
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const addPasswordAuthentication = async () => {
    setState({ isLoading: true, error: null });
    try {
      const addPasswordToUser = firebase
        .app()
        .functions('europe-west6')
        .httpsCallable('addPasswordToUser');
      await addPasswordToUser({ uid: user.uid, password });
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      return history.push('/');
    } catch (e) {
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const signUp = async () => {
    setState({ isLoading: true, error: null });
    try {
      if (user) {
        const credential = EmailAuthProvider.credential(email, password);
        await linkWithCredential(user, credential);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
      }
      return history.push('/');
    } catch (e) {
      setState({ error: errorStrings[e.code] || e });
    } finally {
      setState({ isLoading: false });
    }
  };

  const logIn = async () => {
    setState({ isLoading: true, error: null, verificationId: null });
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return;
    } catch (e) {
      // Make sure to check if multi factor authentication is required
      if (e.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, e);
        setResolver(resolver);
        if (resolver.hints.length > 1) {
          // Use resolver.hints to display a list of second factors to the user
        }
        // Currently only phone based factors are supported
        if (
          resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID
        ) {
          const phoneOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };
          const recaptchaVerifier = setRecaptcha();
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          return phoneAuthProvider
            .verifyPhoneNumber(phoneOptions, recaptchaVerifier)
            .then(verificationId =>
              setState(
                {
                  showCodeInput: true,
                  code: '',
                  phone: '',
                  verificationId,
                },
                startTimer(),
              ),
            );
        }
      } else {
        setState({ error: errorStrings[e.code] || e });
      }
    } finally {
      setState({ isLoading: false });
    }
  };

  const signUpOrLogIn = async () => {
    if (mode === 'logIn') {
      await logIn();
    } else {
      await signUp();
    }
  };

  const isEnrolling = mode === 'enroll';
  const createPasswordText = t('Wählen Sie ein Passwort');

  const signUpOrLogInTitle = showPasswordInput
    ? mode === 'logIn'
      ? t('Geben Sie Ihr Passwort ein')
      : createPasswordText
    : t('Melde dich mit deiner E-Mail an.');

  return (
    <Form>
      <div className='flex flex-col justify-between'>
        {mode === 'enroll' && (
          <>
            <h1>{t('2-Faktor-Authentifizierung')}</h1>
            <p className='my-4'>
              {t(
                'Caveo verwendet jetzt eine 2-Faktor-Authentifizierung mit E-Mail und Passwort. Du wirst aufgefordert, ein Passwort und einen Bestätigungscode einzugeben.',
              )}
            </p>
          </>
        )}
        <h3
          className={`text-denim leading-tight ${
            isEnrolling ? 'mb-4' : 'mb-20'
          }`}
        >
          {isEnrolling ? createPasswordText : signUpOrLogInTitle}
        </h3>
        <InputButtonCombination
          inputProps={{
            type: showPasswordInput ? 'password' : 'email',
            placeholder: showPasswordInput ? 'Password' : 'E-Mail',
            value: showPasswordInput ? password : email,
            onChange: e =>
              setState({
                [showPasswordInput ? 'password' : 'email']: e.target.value,
              }),
          }}
          buttonProps={{
            isLoading,
            disabled: isLoading,
            label: t('Weiter'),
            id: 'sign-in-button',
            onClick: () =>
              showPasswordInput
                ? isEnrolling
                  ? addPasswordAuthentication()
                  : signUpOrLogIn()
                : submitEmail(),
          }}
        />
        {error && <Text color='status-error'>{error?.message || error}</Text>}
        {!user && (
          <Link
            className='mt-8'
            to={`${history.location.pathname}?type=business`}
          >
            {t('Kein Privatkunde? Dann klicke hier.')}
          </Link>
        )}
      </div>
    </Form>
  );
}
