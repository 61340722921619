import React, { useState } from 'react';
import { Anchor } from 'grommet';
import { useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { format } from 'date-fns';
import { useQuery } from '../../hooks';

function UploadFile() {
  const { t } = useTranslation('components');
  const query = useQuery();
  const history = useHistory();
  const [title, setTitle] = useState();
  const [type, setType] = useState(query.get('documentType') || 'other');
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const handleFileChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    setFile(file);
  };

  const submitUpload = async () => {
    setLoading(true);
    setError(null);
    try {
      const currentUser = firebase.auth().currentUser.uid;
      const dateTime = format(new Date(), 'yyyy_MM_dd_HH_mm_ss');
      const documentName =
        type === 'tax'
          ? 'taxDeclaration'
          : type === 'pension'
          ? 'pensionFundExcerpt'
          : file.name.split('.')[0];
      const fileExtension = file.name.split('.')[1];
      const fileName = `${documentName}_${dateTime}.${fileExtension}`;
      const reference = firebase
        .storage()
        .ref(`userUploads/files/${currentUser}/${fileName}`);
      const uploadTask = reference.put(file, {
        cacheControl: 'max-age=31536000',
      });
      const downloadUrl = await uploadTask.then(() =>
        reference.getDownloadURL(),
      );
      if (!downloadUrl) {
        throw new Error('Failed to upload document, please try again');
      }
      await firebase
        .firestore()
        .collection('userUploads')
        .add({
          title,
          type,
          createDate: new Date(),
          createdBy: currentUser,
          user: currentUser,
          file: { title: fileName, src: downloadUrl },
        });
      firebase.analytics().logEvent('userUpload_created');
      setTitle();
      setFile();
      history.push('/dashboard/user-uploads/sent');
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const typeOptions = [
    {
      value: 'tax',
      label: t('Steuererklärung'),
    },
    {
      value: 'pension',
      label: t('Pensionskassen-Auszug'),
    },
    {
      value: 'other',
      label: t('Anderes'),
    },
  ];

  return (
    <div className='rounded-md mb-4 p-4 w-full'>
      <div className='my-4'>
        <input
          type='text'
          className='bg-superlightteal w-full resize-none rounded-md placeholder-teal border-0'
          placeholder={t('Name')}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div className='my-4'>
        <select
          value={type}
          onChange={e => setType(e.target.value)}
          className='bg-superlightteal w-full resize-none rounded-md placeholder-teal border-0'
        >
          {typeOptions.map(({ label, value }) => (
            <option label={label} value={value} key={label} />
          ))}
        </select>
      </div>
      <div className='flex flex-row justify-between p-2 bg-superlightteal my-4'>
        <div flex='shrink'>
          <label htmlFor='upload'>
            <Anchor
              plain
              size='small'
              color='neutral-1'
              label={file?.name || t('Anhang wählen')}
              icon={<BsUpload size='24px' />}
              style={{
                fontWeight: 'normal',
              }}
            />
            <input
              type='file'
              id='upload'
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept='application/pdf'
            />
          </label>
        </div>
      </div>
      <Button
        onClick={submitUpload}
        disabled={!title || !file}
        label={t('Senden')}
        className='my-4'
        isLoading={isLoading}
      />
      {error && <p className='text-red'>{error}</p>}
    </div>
  );
}

export default UploadFile;
