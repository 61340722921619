import React from 'react';

function DashboardContainer({ children, className }) {
  return (
    <div
      className={`bg-lightgrey shadow-md rounded-lg overflow-hidden h-full ${className}`}
    >
      {children}
    </div>
  );
}

export default DashboardContainer;
