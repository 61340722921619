import React, { useContext, useState } from 'react';
import { UserDataContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import CaveoSwitch from '../../components/CaveoSwitch';
import { makeCurrencyString } from '../../components/PolicyOrOfferDetails';

function CostChart(props) {
  const { t } = useTranslation();
  const { policies, categories } = useContext(UserDataContext);
  const [mode, setMode] = useState('yearly');

  if (!policies || policies.length === 0) {
    return null;
  }

  let total = 0;
  policies.forEach(policy => {
    total += policy.premium;
  });

  // Add the premiums of the same policy types:
  const barsByType = policies.reduce((object, policy) => {
    const categoryShortName = categories?.find(
      c => c.id === policy.categories[0],
    )?.shortName;
    object[categoryShortName] = object[categoryShortName]
      ? object[categoryShortName] + policy.premium
      : policy.premium;
    return object;
  }, {});

  const getBarWidth = index => {
    const numBars = Object.keys(barsByType).length;
    const maxWidth = 100; // percent
    const minWidth = 30; // percent
    const difference = maxWidth - minWidth;
    const stepSize = difference / (numBars - 1);
    const width = maxWidth - stepSize * index;
    return `${width}%`;
  };

  return (
    <div className='p-4' id='costChartContainer'>
      <div className='flex flex-row justify-between'>
        <CaveoSwitch
          id='period-toggler'
          labels={{
            left: t('Jahr'),
            right: t('Monat'),
          }}
          isChecked={mode === 'monthly'}
          onChange={() => setMode(mode === 'monthly' ? 'yearly' : 'monthly')}
        />
        <h5>
          {makeCurrencyString(
            mode === 'yearly' ? total / 100 : total / 100 / 12,
          )}
        </h5>
      </div>
      {Object.keys(barsByType)
        .sort((a, b) => barsByType[b] - barsByType[a])
        .map((type, index) => (
          <div key={type}>
            <div className='flex flex-row justify-between mt-4 w-full relative'>
              <div
                className='h-6 bg-gradient-to-r from-ocean to-arctic rounded-full flex flex-row justify-between py-1 px-2'
                style={{ width: getBarWidth(index) }}
              />
              <div className='absolute h-6 flex items-center justify-between w-full py-1 px-2'>
                <p numberOfLines={1} className='text-arctic text-sm'>
                  {t(`insuranceTypes::short::${type}`)}
                </p>
                <p numberOfLines={1} className='text-ocean text-sm text-right'>
                  {makeCurrencyString(
                    mode === 'yearly'
                      ? barsByType[type] / 100
                      : barsByType[type] / 100 / 12,
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default CostChart;
