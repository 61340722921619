import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';
import CombinedTextFileField from '../../components/CombinedTextFileField';
import FullScreenLoader from '../../components/FullScreenLoader';
import { logError } from '../../utils/logging';

function RequestForm({ policy, type }) {
  const { t } = useTranslation();
  const history = useHistory();

  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // Clear data if type was changed:
    setMessage('');
    setFile(null);
  }, [type]);

  const sendForm = async () => {
    setLoading(true);
    setError(null);
    try {
      // Upload file:
      let downloadUrl;
      if (file) {
        const reference = firebase
          .storage()
          .ref(
            `userUploads/${
              firebase.auth().currentUser.uid
            }/${new Date().toISOString()}_${file.name}`,
          );
        const uploadTask = reference.put(file, {
          cacheControl: 'max-age=31536000',
        });
        downloadUrl = await uploadTask.then(() => reference.getDownloadURL());
      }
      // Create task in CRM:
      const addTask = firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('addTask');
      const task = {
        type:
          type === 'changePolicy' ? 'policyChangeRequested' : 'offerRequested',
        user: firebase.auth().currentUser.uid,
        info: message,
        policy: policy.id,
      };
      if (downloadUrl) {
        task.files = [{ title: file.name, src: downloadUrl }];
      }
      const result = await addTask(task);
      const taskID = result.data;
      if (!taskID) {
        throw new Error('Could not create task.');
      }
      setLoading(false);
      // Clear data:
      setMessage('');
      setFile(null);
      history.push(`/dashboard/choose-answer-channel?taskID=${taskID}`);
    } catch (e) {
      logError(e);
      setError(t('Nachricht konnte nicht gesendet werden.'));
      setLoading(false);
    }
  };

  return (
    <div>
      {error && <p className='text-red'>{error}</p>}
      <CombinedTextFileField
        placeholder={t(
          type === 'changePolicy'
            ? 'Adressänderung, Kündigung, Policenänderung…'
            : 'Spezielle Wünsche angeben…',
        )}
        text={message}
        onTextChange={e => setMessage(e.target.value)}
        file={file}
        onFileChange={setFile}
        disableSend={message.length === 0}
        onSend={sendForm}
      />
      {isLoading && <FullScreenLoader />}
    </div>
  );
}

export default RequestForm;
