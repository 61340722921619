import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Profile from './Profile';
import MakeClaim from '../make-claim/MakeClaim';
import Notifications from '../notifications/Notifications';
import Mandate from './Mandate';
import { fetchEntitiesForUser } from '../../store/actions';
import { connect } from 'react-redux';
import { isFetchingEntities } from '../../store/actions/entities';
import { Anchor, Paragraph } from 'grommet';
import Dashboard from './Dashboard';
import Policy from '../policy/Policy';
import ChooseCommunicationChannel from '../../components/ChooseCommunicationChannel';
import ClaimSent from '../make-claim/ClaimSent';
import Offer from '../offer/Offer';
import OfferSigned from '../offer/OfferSigned';
import RequestOffer from '../request-offer/RequestOffer';
import DashboardLayout from '../../layouts/DashboardLayout';
import MortgageCalculator from '../mortgage-calculator/MortgageCalculator';
import Document from '../document/Document';
import DocumentSigned from '../document/DocumentSigned';
import UserUpload from '../userUpload/UserUpload';
import UploadFile from '../userUpload/UploadFile';
import FileUploaded from '../userUpload/FileUploaded';
import MyDocuments from '../userUpload/MyDocuments';
import Referral from '../referral/Referral';

const mapStateToProps = state => ({
  signedIn: state.user.signedIn,
  status: state.user.status,
  isFetchingPolicies: state.ui.isFetching.policies,
  isFetchingOffers: state.ui.isFetching.offers,
  isFetchingNotifications: state.ui.isFetching.notifications,
});

const mapDispatchToProps = dispatch => ({
  fetchEntitiesForUser: entity => dispatch(fetchEntitiesForUser(entity)),
  isFetchingEntities: data => dispatch(isFetchingEntities(data)),
});

class DashboardRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isFetchingPolicies) {
      this.updateEntities('policies');
    }
    if (!this.props.isFetchingOffers) {
      await this.updateEntities('offers');
    }
    if (!this.props.isFetchingNotifications) {
      this.updateEntities('notifications');
    }
    this.setState({ isLoading: false });
  }

  updateEntities = async type => {
    this.props.isFetchingEntities({ entityType: type, isFetching: true });
    return this.props
      .fetchEntitiesForUser(type)
      .then(() =>
        this.props.isFetchingEntities({ entityType: type, isFetching: false }),
      );
  };

  render() {
    const { match, status } = this.props;
    if (['inactive', 'archived'].includes(status)) {
      return (
        <Paragraph>
          Ihr Konto ist inaktiv. Bitte setzen Sie sich mit uns in Kontakt:{' '}
          <Anchor href={'mailto:hello@caveo.ch'}>hello@caveo.ch</Anchor>
        </Paragraph>
      );
    }
    return (
      <DashboardLayout>
        <Switch>
          <Route
            path={`${match.url}/offers/:offerID/signed`}
            component={OfferSigned}
          />
          <Route path={`${match.url}/offers/:offerID`} component={Offer} />
          <Route
            path={`${match.url}/documents/:documentID/signed`}
            component={DocumentSigned}
          />
          <Route
            path={`${match.url}/documents/:documentID`}
            component={Document}
          />
          <Route
            path={`${match.url}/user-uploads/create`}
            component={UploadFile}
          />
          <Route
            path={`${match.url}/user-uploads/sent`}
            component={FileUploaded}
          />
          <Route
            path={`${match.url}/user-uploads/:userUploadID`}
            component={UserUpload}
          />
          <Route path={`${match.url}/user-uploads`} component={MyDocuments} />
          <Route path={`${match.url}/policies/:policyID`} component={Policy} />
          <Route path={`${match.url}/profile`} component={Profile} />
          <Route path={`${match.url}/request-offer`} component={RequestOffer} />
          <Route path={`${match.url}/make-claim`} component={MakeClaim} />
          <Route path={`${match.url}/referral`} component={Referral} />
          <Route
            path={`${match.url}/mortgage-calculator`}
            component={MortgageCalculator}
          />
          <Route path={`${match.url}/claim-sent`} component={ClaimSent} />
          <Route
            path={`${match.url}/notifications`}
            component={Notifications}
          />
          <Route path={`${match.url}/mandate`} component={Mandate} />
          <Route
            path={`${match.url}/choose-answer-channel`}
            component={ChooseCommunicationChannel}
          />
          <Route path={match.path} component={Dashboard} />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DashboardRoutes));
