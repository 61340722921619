import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../hooks';
import Button from './Button';

function ChooseCommunicationChannel(props) {
  const { t } = useTranslation();
  const query = useQuery();
  const taskID = query.get('taskID');
  const history = useHistory();
  const [error, setError] = useState(null);

  const buttons = [
    {
      label: t('Ruft mich an'),
      value: 'phone',
    },
    {
      label: t('Schreibt mir im Chat'),
      value: 'chat',
    },
    {
      label: t('Schreibt mir eine E-Mail'),
      value: 'email',
    },
  ];

  const saveAnswerChannel = async answerChannel => {
    try {
      await firebase
        .firestore()
        .collection('tasks')
        .doc(taskID)
        .update({ answerChannel });
      history.push('/dashboard');
    } catch (e) {
      setError(e.message);
    }
  };
  return (
    <div>
      <h1 className='text-denim leading-tight'>{t('Vielen Dank.')}</h1>
      <h1 className='text-denim leading-tight'>
        {t(
          'Wir haben deine Anfrage erhalten. Wie möchtest du von uns kontaktiert werden?',
        )}
      </h1>
      <div className='flex md:flex-row mt-8'>
        {buttons.map(({ label, value }, index) => (
          <div key={label} className='mr-2'>
            <Button
              primary
              color={'text-denim'}
              backgroundColor={'white'}
              className='border-denim border shadow-none hover:bg-denim hover:text-white'
              label={label}
              onClick={() => saveAnswerChannel(value)}
            />
          </div>
        ))}
      </div>
      {error && <p className='text-red'>{error}</p>}
    </div>
  );
}

export default ChooseCommunicationChannel;
