import {
  TOGGLE_DRAWER,
  IS_FETCHING_ENTITIES,
  CHANGE_LANGUAGE,
} from '../actions/actionTypes';

const initialState = {
  openDrawers: {},
  isFetching: {
    notifications: false,
    policies: false,
    offers: false,
  },
  language: navigator.language || navigator.browserLanguage,
};

const openDrawers = (state, action) => ({
  ...state,
  [action.side]: !state[action.side],
});

const isFetching = (state, action) => ({
  ...state,
  [action.entityType]: action.isFetching,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        openDrawers: openDrawers(state.openDrawers, action),
      };
    case IS_FETCHING_ENTITIES:
      return {
        ...state,
        isFetching: isFetching(state.isFetching, action),
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
};

export default reducer;
