import Switch from 'react-switch';
import React from 'react';
import { ocean, teal } from '../theme';

function CaveoSwitch({ isChecked, onChange, labels, ...rest }) {
  return (
    <label
      className='flex flex-grow-0 justify-start center-items'
      htmlFor='material-switch'
    >
      <span className='text-ocean'>{labels.left}</span>
      <Switch
        checked={isChecked}
        onChange={onChange}
        onColor={teal}
        offColor={teal}
        onHandleColor={ocean}
        offHandleColor={ocean}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
        height={8}
        width={40}
        className='mx-2'
        {...rest}
      />
      <span className='text-ocean'>{labels.right}</span>
    </label>
  );
}

export default CaveoSwitch;
