import React, { useContext, useState, useEffect } from 'react';
import { UserDataContext } from '../../contexts';
import Grid from '../../components/Grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import { isSameYear } from 'date-fns';

function MyDocuments() {
  const { t } = useTranslation();
  const history = useHistory();
  const { userUploads, documents } = useContext(UserDataContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Retrieves the tax or pension filing for the current year
    const getAnnualDocumentFiling = type =>
      userUploads.find(
        upload =>
          upload?.type === type && isSameYear(upload.createDate, new Date()),
      );
    // Creates a tax or pension item to be displayed
    const createTaxOrPensionData = (document, type) => {
      const item = document ? document : { id: `create?documentType=${type}` };
      const title =
        type === 'tax' ? t('Steuererklärung') : t('Pensionskassenauszug');
      return { ...item, title };
    };
    if (userUploads && documents) {
      const items = [];
      // Get the latest pensionFundExcerpt and taxDeclaration documents
      const taxDocument = getAnnualDocumentFiling('tax');
      items.push(createTaxOrPensionData(taxDocument, 'tax'));
      const pensionPlanDocument = getAnnualDocumentFiling('pension');
      items.push(createTaxOrPensionData(pensionPlanDocument, 'pension'));
      setData(
        items.concat(
          [
            ...userUploads
              .filter(
                u => ![pensionPlanDocument?.id, taxDocument?.id].includes(u.id),
              )
              .map(d => ({
                ...d,
                ext: d.file.title
                  .split('.')
                  .pop()
                  .toUpperCase(),
              })),
            ...documents
              .filter(d => d.showInMyDocuments)
              .map(d => ({
                ...d,
                isDocument: true,
                ext: d.file.title
                  .split('.')
                  .pop()
                  .toUpperCase(),
              })),
          ].sort((a, b) => a.createDate < b.createDate),
        ),
      );
    }
  }, [userUploads, documents, setData, t]);

  if (!userUploads) {
    return null;
  }

  return (
    <Grid
      items={data.map((item, index) => (
        <div key={index} className='m-4 flex flex-col items-center'>
          <button
            className={`w-40 h-40 rounded-lg ${
              item?.file ? 'shadow-lg' : 'border border-denim'
            } flex justify-center`}
            onClick={() =>
              history.push(
                `/dashboard/${item.isDocument ? 'documents' : 'user-uploads'}/${
                  item.id
                }`,
              )
            }
          >
            {item?.file ? (
              item?.thumbnail ? (
                <img
                  src={item.thumbnail.src}
                  alt={item.thumbnail.title}
                  className='rounded-lg w-full h-full'
                />
              ) : (
                <p className='text-center text-4xl m-auto'>{item.ext}</p>
              )
            ) : (
              <div className='w-full'>
                <div className='h-1/2 flex items-end justify-center'>
                  <MdAdd size={30} className='text-center text-4xl' />
                </div>
                <div className='h-1/2 mx-2 flex justify-center'>
                  <p className='text-lg text-center line-clamp-2 break-words'>
                    {item.title}
                  </p>
                </div>
              </div>
            )}
          </button>
          {item.file && (
            <p className='mt-4 text-lg text-center truncate'>
              {item.title || item.name}
            </p>
          )}
        </div>
      ))}
    />
  );
}

export default MyDocuments;
