import React from 'react';
import classnames from 'classnames';
import ButtonBase from './ButtonBase';

function FlatButton({
  label,
  onClick,
  to,
  href,
  secondary,
  className,
  isLoading,
  disabled,
  icon,
  ...rest
}) {
  const isDisabled = isLoading || disabled;
  return (
    <ButtonBase
      disabled={isDisabled}
      className={classnames([
        className,
        'px-8',
        'font-semibold',
        'rounded-full',
        secondary ? 'bg-transparent' : 'bg-arctic',
        secondary ? 'text-denim' : 'text-denim',
        'border',
        secondary ? 'border-denim' : 'border-arctic',
        {
          'opacity-50': isDisabled,
        },
      ])}
      onClick={onClick}
      to={to}
      href={href}
      isLoading={isLoading}
      label={label}
      icon={icon}
      {...rest}
    />
  );
}

export default FlatButton;
