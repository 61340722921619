import { TOGGLE_USER_STATUS, SET_USER, SIGN_OUT } from './actionTypes';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { logError } from '../../utils/logging';
import { shortLanguage } from '../../i18n';

export const toggleUserStatus = status => {
  return {
    type: TOGGLE_USER_STATUS,
    ...status,
  };
};

export const signOut = () => ({ type: SIGN_OUT });

export async function getUserInfo(token) {
  return async dispatch => {
    if (token) {
      const user = await getUser(token);
      if (!user) {
        return;
      }
      const userData = user.data();
      dispatch(setUser(userData));
      return user;
    } else {
      return;
    }
  };
}

export async function getUser(userID) {
  try {
    const user = await firebase
      .firestore()
      .collection('users')
      .doc(userID)
      .get()
      .then(user => {
        if (!user.exists) {
          return;
        }
        return user.data();
      });
    return user;
  } catch (err) {
    logError(`Error getting user users/${userID}: `, err);
    return;
  }
}

export const setUser = data => ({
  type: SET_USER,
  data,
});

export const createUser = async (userID, data) => {
  // Create user document in Firestore:
  const userRef = firebase
    .firestore()
    .collection('users')
    .doc(userID);
  const body = {
    ...data,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    type: 'lead',
    language: shortLanguage,
  };
  // Save the campaign, source and invitedBy if present:
  const campaign = window.localStorage.getItem('campaign');
  const invitedBy = window.localStorage.getItem('invitedBy');
  const source = window.localStorage.getItem('source');
  if (campaign) {
    body.campaign = campaign;
    window.localStorage.removeItem('campaign');
  }
  if (source) {
    body.source = source;
    window.localStorage.removeItem('source');
  }
  if (invitedBy) {
    // Save the person who invited this new user:
    body.invitedBy = invitedBy;
    // If the user was invited by a consultant, make the consultant the user's consultant
    // Otherwise, make the inviter's consultant the user's consultant
    const inviter = await getUser(invitedBy);
    if (inviter?.isConsultant || inviter?.consultant) {
      body.consultant = inviter?.consultant ? invitedBy : inviter.consultant;
    }
    await firebase
      .firestore()
      .collection('referrals')
      .add({
        referrer: invitedBy,
        user: userID,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
    window.localStorage.removeItem('invitedBy');
  }
  await userRef.set(body, { merge: true });
  setUser(body);
};
