import React, { useContext, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import SignaturePad from '../../components/SignaturePad/SignaturePad';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../contexts';
import { useHistory } from 'react-router-dom';
import { updateUser } from '../../api/user';
import FlatButton from '../../components/FlatButton';
import { logError } from '../../utils/logging';

function SignMandate() {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useContext(ProfileContext);
  const signaturePad = useRef(null);

  const { isCompany, onboardingProgress } = profile;

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const uploadSignature = async () => {
    if (signaturePad.current.isEmpty()) {
      return alert(t('Bitte unterschreibe im Feld.'));
    }
    setLoading(true);
    try {
      const userID = firebase.auth().currentUser.uid;
      const base64PNG = signaturePad.current.getTrimmedCanvas().toDataURL();
      const storage = firebase.storage();
      const storageRef = storage.ref(`signatures/mandates/${userID}.png`);
      // Save signature to storage – the signed PDF mandate is created by a Cloud Storage trigger.
      const [downloadURL, signaturePath] = await storageRef
        .putString(base64PNG, 'data_url')
        .then(async snapshot => {
          const url = await snapshot.ref.getDownloadURL();
          const path = snapshot.ref.fullPath;
          return [url, path];
        });
      if (!downloadURL || !signaturePath) {
        throw new Error(t('Unterschrift konnte nicht gespeichert werden.'));
      }
      // Save download URL to database:
      const nextStep = isCompany ? 'referrer' : 'upload-identification';
      const body = {
        signature: downloadURL,
        onboardingProgress: nextStep,
      };
      await updateUser({
        ...body,
        modified: firebase.firestore.FieldValue.serverTimestamp(),
      });
      firebase.analytics().logEvent('mandate_signed', { platform: 'web' });
      history.push(`/onboarding/${nextStep}`);
    } catch (error) {
      logError(error);
      setError(error);
    }
  };

  return (
    <OnboardingLayout
      backgroundColor='bg-flamingo'
      step='sign-mandate'
      onboardingProgress={onboardingProgress}
    >
      <div className='lg:w-1/2'>
        <h1 className='text-denim mb-8 leading-tight'>
          {t('Unterschreibe im Feld mit deiner Maus.')}
        </h1>
        <SignaturePad
          ref={signaturePad}
          className='bg-flamingo border-denim'
          onButtonClick={uploadSignature}
          buttonLabel={t('Weiter')}
        />
        <FlatButton
          secondary
          label={t('Weiter')}
          onClick={uploadSignature}
          type='submit'
          className='bg-denim text-white self-end'
          isLoading={isLoading}
        />
      </div>
      {error && <p className='text-red'>{error}</p>}
    </OnboardingLayout>
  );
}

export default SignMandate;
