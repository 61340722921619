import firebase from 'firebase/compat/app';
import { logError } from '../utils/logging';

export function listenToReferrals(handleNewData) {
  return firebase
    .firestore()
    .collection('referrals')
    .where('referrer', '==', firebase.auth().currentUser.uid)
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData({ numReferrals: 0 });
      } else {
        const numReferrals = snapshot.size;
        handleNewData({ numReferrals });
      }
    }, logError);
}
