import firebase from 'firebase/compat/app';
import { convertQuerySnapshot } from 'firebase-helpers';
import { logError } from '../utils/logging';

export function listenToNotifications(handleNewData) {
  return firebase
    .firestore()
    .collection('notifications')
    .where('user', '==', firebase.auth().currentUser.uid)
    .onSnapshot(snapshot => {
      if (snapshot.empty) {
        handleNewData([]);
      } else {
        const data = convertQuerySnapshot(snapshot);
        handleNewData(data);
      }
    }, logError);
}

export const markNotificationsAsRead = notificationIDs => {
  const db = firebase.firestore();
  const batch = db.batch();
  notificationIDs.forEach(id => {
    const ref = db.collection('notifications').doc(id);
    batch.update(ref, { isRead: true });
  });
  return batch.commit();
};
