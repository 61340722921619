import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import { logError } from '../../utils/logging';
import Loader from 'react-spinners/ScaleLoader';

export default function DeepLink() {
  const { t } = useTranslation();

  const [branchLink, setBranchLink] = useState();
  const [error, setError] = useState();

  /**
   * Create a Branch.io link to open or scan with the mobile phone.
   */
  useEffect(() => {
    async function getBranchLink() {
      setError();
      // Get all search params from the URL
      const searchParams = new URLSearchParams(window.location.search);
      // Some parameters are modified by Branch.io. We'll make sure to pass them again to the API:
      const campaign =
        searchParams.get('campaign') || searchParams.get('utm_campaign');
      const feature = searchParams.get('utm_medium');
      const channel = searchParams.get('utm_source');
      const link = `https://app.caveo.ch${
        window.location.pathname
      }?${searchParams.toString()}`;
      const branchParams = {
        link,
        ...(campaign ? { campaign } : {}),
        ...(feature ? { feature } : {}),
        ...(channel ? { channel } : {}),
      };
      // Create the link:
      const { data } = await firebase
        .app()
        .functions('europe-west6')
        .httpsCallable('createBranchLink')(branchParams);
      // If an error occurs, log to crashlytics and ask the user to refresh the page
      if (data?.error) {
        logError(data.error);
        setError(t('An error occured, please refresh the page.'));
        return;
      }
      setBranchLink(data.url);
    }
    getBranchLink();
  }, [t]);

  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent,
  );

  // Redirect automatically on mobile devices.
  useEffect(() => {
    if (branchLink && !error && isMobileDevice) {
      window.location.replace(branchLink);
    }
  }, [branchLink, error, isMobileDevice]);

  if (!branchLink && !error) {
    return <Loader />;
  }

  if (error) {
    return <p className='text-red'>{error}</p>;
  }

  // On desktop computers, show a QR code which can be scanned.
  return (
    <div className='flex flex-col justify-between'>
      <div className='space-y-4 text-center flex flex-col items-center'>
        <h1>{t("Weiter geht's in der App.")}</h1>
        <p>{t('Scan den QR-Code, um die Caveo-App zu öffnen.')}</p>
        <QRCode size={400} value={branchLink} />
      </div>
    </div>
  );
}
