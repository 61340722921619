import React from 'react';
import classnames from 'classnames';
import ButtonBase from './ButtonBase';

function Button({
  label,
  onClick,
  to,
  secondary,
  plain,
  className,
  color,
  backgroundColor,
  isLoading,
  disabled,
  icon,
  ...rest
}) {
  const isDisabled = isLoading || disabled;
  const props = {
    ...rest,
    onClick,
    to,
    icon,
    isLoading,
    label,
    disabled: isDisabled,
    className: classnames([
      className,
      secondary || plain ? 'px-4' : 'px-8',
      secondary || plain ? 'font-normal' : 'font-semibold',
      secondary || plain ? 'rounded-md' : 'rounded-full',
      !plain && 'shadow-lg',
      // Background:
      backgroundColor ||
        (secondary
          ? 'bg-lightteal dark:bg-arctic'
          : plain
          ? 'transparent'
          : 'bg-teal dark:bg-lightteal'),
      // Background hover:
      secondary ? 'hover:bg-teal' : plain ? 'transparent' : 'hover:bg-ocean',
      // Text:
      color ||
        (secondary
          ? 'text-denim'
          : plain
          ? 'text-teal dark:text-lightteal'
          : 'text-white dark:text-denim'),
      {
        'opacity-50': isDisabled,
      },
    ]),
  };
  return <ButtonBase {...props} />;
}

export default Button;
