import { ScaleLoader } from 'react-spinners';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

function ButtonBase({
  label,
  onClick,
  to,
  href,
  className,
  isLoading,
  disabled,
  icon,
  type,
  ...rest
}) {
  const isDisabled = isLoading || disabled;
  const props = {
    onClick,
    to,
    href,
    disabled: isDisabled,
    className: classnames([
      className,
      'py-2 flex flex-row flex-initial justify-center items-center focus:outline-none outline-none',
      {
        'opacity-50 cursor-default': isDisabled,
        'hover:shadow-lg': !isDisabled,
      },
    ]),
    style: { outline: 'none' },
    children: [
      icon && <div className='mr-2'>{icon}</div>,
      isLoading ? <ScaleLoader height={10} /> : label,
    ],
    ...rest,
  };
  if (href) {
    return <a {...props}>{props.children}</a>;
  }
  return onClick || type === 'submit' ? (
    <button {...props} />
  ) : (
    <Link {...props} />
  );
}

export default ButtonBase;
