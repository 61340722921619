import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDataContext } from '../../contexts';
import { IoPersonCircleSharp } from 'react-icons/io5';
import { denim, teal } from '../../theme';
import firebase from 'firebase/compat/app';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Text } from 'grommet';

function Referral() {
  const { t } = useTranslation(['referral']);
  let { referrals } = useContext(UserDataContext);
  const numReferrals = referrals ? referrals.numReferrals : 0;
  const colors = Array(numReferrals)
    .fill(teal)
    .concat(Array(10 - numReferrals).fill(denim));
  const iconSize = 75;

  const [referralLink, setReferralLink] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    async function getReferralLink() {
      setError();
      const userID = firebase.auth().currentUser.uid;
      const link = `https://app.caveo.ch/sign-in?invitedBy=${userID}`;
      const { data } = await firebase
        .app()
        .functions('europe-west6')
        .httpsCallable('createBranchLink')({
        link,
        channel: 'shareDialog',
        feature: 'web',
        campaign: 'referral',
      });
      if (data?.error) {
        setError(data.error);
        return;
      }
      setReferralLink(data.url);
    }
    getReferralLink();
  }, []);

  return (
    <div className='p-8 mb-8 max-w-2xl'>
      <h1 className='text-denim mb-8'>{t('referral//title')}</h1>
      <h3 className='text-denim mb-8'>{t('referral//subtitle')}</h3>
      <p className='text-denim mb-8'>{t('referral//explanation')}</p>
      <p className='text-denim mb-2'>{t('referral//yourLink')}</p>
      {error ? (
        <Text color='red'>{error}</Text>
      ) : (
        !referralLink && <ScaleLoader height='20' color={denim} />
      )}
      {referralLink && (
        <a className='text-xl ' href={referralLink}>
          {referralLink}
        </a>
      )}
      <p className='text-denim mb-4 mt-4'>
        {t('referral//progressExplanation')}
      </p>
      <div className='grid max-w-xl grid-flow-row grid-cols-5 justify-center'>
        {colors.map((color, i) => (
          <IoPersonCircleSharp key={i} size={iconSize} color={color} />
        ))}
      </div>
    </div>
  );
}

export default Referral;
